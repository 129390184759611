import styled, { css } from 'styled-components';

import { colors } from '../../constants/theme';

export type ReservedButtonVariant =
  // | 'secondary'
  // | 'outlined'
  // | 'contained'
  'primary';

export type ButtonVariant = ReservedButtonVariant;

const variantCssMap: Record<ReservedButtonVariant, ReturnType<typeof css>> = {
  primary: css`
    background-color: ${colors.green};
    color: #ffff;
    &:disabled {
      background-color: ${colors.gray};
    }
  `,
};

export const StyledButton = styled.button<{ variant?: ButtonVariant }>`
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
  text-align: center;
  border-radius: 59px;
  transition: 0.3s;
  padding: 16.5px 65.5px;
  display: inline-block;
  &:hover {
    opacity: 0.8;
  }
  ${(props) =>
    typeof props.variant === 'string'
      ? variantCssMap[props.variant]
      : props.variant};
  &:disabled {
    cursor: default;
    &:hover {
      opacity: 1;
    }
  }
`;
