import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { colors } from '../../../../../../../constants/theme';

type Props = {
  name: string;
  image: string;
  login: string;
};

function MemberInfo({ name, login, image }: Props) {
  const { t } = useTranslation();

  const splitName = name ? name.split(' ') : [];
  return (
    <PresenterData>
      {image ? (
        <ImageContainer>
          <Image src={image} />
        </ImageContainer>
      ) : splitName.length ? (
        <TextContainer>
          {splitName[0].substr(0, 1) + splitName[1].substr(0, 1)}
        </TextContainer>
      ) : (
        <TextContainer>{t('unknown').substr(0, 1)}</TextContainer>
      )}
      <StreamerInfo>
        <Name>{name ? name : t('unknown')}</Name>
        {/*<Website>@{login}</Website>*/}
      </StreamerInfo>
    </PresenterData>
  );
}

export default MemberInfo;

const PresenterData = styled.div`
  display: flex;
  align-items: center;
`;

const ImageContainer = styled.div``;

const TextContainer = styled.div`
  border-radius: 50%;
  width: 48px;
  height: 48px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  background-color: ${colors.grayLight2};
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

const Image = styled.img`
  border-radius: 50%;
  width: 48px;
  height: 48px;
  object-fit: cover;
`;

const StreamerInfo = styled.div`
  margin-left: 20px;
`;

const Name = styled.span`
  display: block;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
`;

const Website = styled.span`
  display: block;
  font-size: 14px;
  line-height: 19px;
  color: ${colors.grayMedium};
`;
