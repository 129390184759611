import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ModalProps } from '../../ModalProvider';
import { ReactComponent as CloseIcon } from '../../../assets/svg/close.svg';
import { ReactComponent as ClockSvg } from '../../../assets/svg/clock.svg';
import { timeLeftToNormalTimeFormat } from '../../../utils/common';

import StreamerInfo from './components/StreamerInfo';

type Props = ModalProps<{
  name: string;
  avatarURL: string | undefined;
  login: string;
  title: string;
  finishDate: number;
  defaultTimeLeft: number;
}>;

function AboutStreamModal({ closeModal, innerProps }: Props) {
  const {
    name,
    avatarURL,
    login,
    title,
    finishDate,
    defaultTimeLeft,
  } = innerProps;

  const [timeLeft, setTimeLeft] = useState(defaultTimeLeft);

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date().getTime();
      setTimeLeft(finishDate * 1000 - now);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Inner>
      <Close onClick={closeModal} />
      <StreamerInfo name={name} avatarURL={avatarURL} login={login} />{' '}
      <Divider />
      <Title>{title}</Title>
      <LengthStream>
        <ClockSvg />
        {timeLeftToNormalTimeFormat(timeLeft)} left
      </LengthStream>
    </Inner>
  );
}

export default AboutStreamModal;

const Inner = styled.div`
  position: relative;
  background-color: #fff;
  z-index: 11;
  max-width: 384px;
  border-radius: 16px;
  text-align: center;
  padding: 40px 24px 40px;
  overflow: hidden;
`;

const Close = styled(CloseIcon)`
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    opacity: 0.6;
  }
`;

const Divider = styled.div`
  display: inline-block;
  background: #000000;
  opacity: 0.1;
  border-radius: 22px;
  height: 1px;
  width: 184px;
  margin: 40px auto;
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  margin: 0 0 34px;
`;

const LengthStream = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: inline-block;
  letter-spacing: -0.5px;
  svg {
    display: block;
    margin: auto auto 6px;
  }
`;
