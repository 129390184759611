import React, { useCallback, useState } from 'react';

import * as S from './TextInput.style';

type Props = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'prefix' | 'onChange'
> & {
  className?: string;
  invalid?: boolean;
  onChange?: (value: string) => void;
};

export type InputRef = HTMLInputElement;

function TextInput(
  { className, disabled, onChange, invalid, ...rest }: Props,
  ref: React.Ref<InputRef>
) {
  const [value, setValue] = useState(rest.value);

  const handleChange = useCallback(
    (event) => {
      setValue(event.target.value);

      if (onChange) {
        onChange(event.target.value);
      }
    },
    [onChange]
  );

  return (
    <S.Container className={className}>
      <S.Input
        ref={ref}
        {...rest}
        onChange={handleChange}
        invalid={invalid}
        value={value}
      />
    </S.Container>
  );
}

export default React.forwardRef<InputRef, Props>(TextInput);
