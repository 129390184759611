import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import ReactGA from 'react-ga';

import { ModalProps, useModal } from '../../ModalProvider';
import { colors } from '../../../constants/theme';
import { ReactComponent as CloseIcon } from '../../../assets/svg/close.svg';
import { ReactComponent as GoogleIcon } from '../../../assets/svg/google.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/svg/facebook.svg';
import { ReactComponent as AppleIcon } from '../../../assets/svg/apple.svg';
import Loader from '../../Loader';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getStreamInfoPage } from '../../../services/requests';
import PaymentModal from '../PaymentModal';

import { loginApple, loginFacebook, loginGoogle } from './auth.helpers';
type Props = ModalProps<{ channelId: string }>;

function LoginModal({ closeModal, innerProps }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { channelId } = innerProps;
  const openModal = useModal();

  const [isLoading, setLoading] = useState(false);

  const { authStatus } = useAppSelector(
    (state) => ({
      authStatus: state.auth.status,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (authStatus === 'AUTHORIZED') {
      getStreamInfoPage(channelId).then((response) => {
        if (!response.data.hasChannelSubscription) {
          openModal(PaymentModal, { channelId: channelId });
        } else {
          document.location.reload();
        }
      });
    }
  }, [authStatus]);

  function handleAppleLogin() {
    setLoading(true);
    loginApple(setLoading, dispatch).then(() => {
      ReactGA.event({
        category: 'User',
        action: 'Login',
        label: 'Apple',
        dimension1: channelId,
      });
    });
  }

  function handleGoogleLogin(
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) {
    setLoading(true);
    loginGoogle(response, setLoading, dispatch)?.then(() => {
      ReactGA.event({
        category: 'User',
        action: 'Login',
        label: 'Google',
        dimension1: channelId,
      });
    });
  }

  function handleFacebookLogin() {
    setLoading(true);
    loginFacebook(setLoading, dispatch, channelId);
  }

  return (
    <Inner>
      <Close onClick={closeModal} />
      {isLoading ? <Loader /> : null}
      <HandBlock>👋</HandBlock>
      <Title>{t('loginModal.title')}</Title>
      <Description>{t('loginModal.desc')}</Description>
      <ButtonBlock>
        <AppleButton onClick={handleAppleLogin}>
          <AppleIcon /> {t('loginModal.appleButton')}
        </AppleButton>
      </ButtonBlock>
      <ButtonBlock>
        <FacebookButton onClick={handleFacebookLogin}>
          <FacebookIcon /> {t('loginModal.facebookButton')}
        </FacebookButton>
      </ButtonBlock>
      <ButtonBlock>
        <GoogleLogin
          clientId="537618151939-49p1aj2jq95tqqlnujdagrh40m0m5mc8.apps.googleusercontent.com"
          onSuccess={(response) => {
            handleGoogleLogin(response);
          }}
          scope={'profile email'}
          render={(renderProps) => (
            <GoogleButton
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              <GoogleIcon /> {t('loginModal.googleButton')}
            </GoogleButton>
          )}
          // onFailure={loginGoogle}
          cookiePolicy={'single_host_origin'}
        />
      </ButtonBlock>
    </Inner>
  );
}

export default LoginModal;

const Inner = styled.div`
  position: relative;
  background-color: #fff;
  z-index: 11;
  min-height: 509px;
  max-width: 405px;
  border-radius: 16px;
  text-align: center;
  padding: 40px 24px 24px;
  overflow: hidden;
`;

const Close = styled(CloseIcon)`
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    opacity: 0.6;
  }
`;

const HandBlock = styled.div`
  display: inline-block;
  background-color: ${colors.yellow};
  border-radius: 50%;
  font-size: 49px;
  padding: 20px 24px;
  margin-bottom: 28px;
  @media (max-width: 767px) {
    margin-bottom: 25px;
  } ;
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 8px;
  } ;
`;

const Description = styled.span`
  display: inline-block;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 24px;
  } ;
`;

const ButtonBlock = styled.div`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const SigInButton = styled.button`
  border-radius: 30px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  width: 100%;
  height: 58px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    vertical-align: bottom;
    margin-right: 12px;
  }
  &:hover {
    opacity: 0.7;
  }
  @media (max-width: 767px) {
    font-size: 15px;
  } ;
`;

const GoogleButton = styled(SigInButton)`
  background-color: #e13f2a;
`;

const FacebookButton = styled(SigInButton)`
  background-color: #3e76ea;
`;

const AppleButton = styled(SigInButton)`
  background-color: #121212;
`;
