export const colors = {
  black: 'black',
  black100: '#101010',
  black200: '#262626',
  black300: '#3b3b3b',
  green: '#00B349',
  gray: '#D8D8D8',
  grayMedium: '#ABABAB',
  grayLight: '#F8F8F8',
  grayLight2: '#E9E9E9',
  yellow: '#FFC938',
  red: 'rgba(254, 130, 103, 1)',
};
