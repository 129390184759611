import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { colors } from '../../../../../constants/theme';
import { useModal } from '../../../../../components/ModalProvider';
import AboutStreamModal from '../../../../../components/Modal/AboutStreamModal';
import { fetchStreamInfoThunk } from '../../../../../store/streamInfo';
import { useAppDispatch } from '../../../../../store/hooks';
import SvgPieTimer from '../../../../../components/SvgPieTimer';

type Props = {
  name: string;
  avatarURL: string | undefined;
  channelTitle: string;
  finishDate: number;
  startDate: number;
};

function AuthorInformation({
  name,
  channelTitle,
  avatarURL,
  finishDate,
  startDate,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const router = useRouteMatch<{ channelId: string }>();

  const splitName = name ? name.split(' ') : [];
  const openModal = useModal();

  const [timeLeft, setTimeLeft] = useState(finishDate * 1000);

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date().getTime();
      if (finishDate * 1000 - now <= 0) {
        dispatch(fetchStreamInfoThunk(router.params.channelId));
        clearInterval(timer);
      } else setTimeLeft(finishDate * 1000 - now);
      // console.log('timeLeft', new Date(timeLeft).toLocaleTimeString());
    }, 1000);
  }, []);

  function handleOpenAboutStreamModal() {
    openModal(AboutStreamModal, {
      name: name,
      avatarURL: avatarURL,
      login: 'test',
      title: channelTitle,
      finishDate: finishDate,
      defaultTimeLeft: timeLeft,
    });
  }

  return (
    <PresenterData onClick={handleOpenAboutStreamModal}>
      {avatarURL ? (
        <ImageContainer>
          <Image src={avatarURL} />
          <Timer>
            <SvgPieTimer
              current={finishDate * 1000 - new Date().getTime()}
              radius={14}
              duration={(startDate - finishDate) * 1000}
              end={finishDate * 1000}
              loops={1}
              reverse={true}
              inverse={true}
            />
          </Timer>
        </ImageContainer>
      ) : splitName.length ? (
        <TextContainer>
          {splitName[0].substr(0, 1) + splitName[1].substr(0, 1)}
        </TextContainer>
      ) : (
        <TextContainer>{t('unknown').substr(0, 1)}</TextContainer>
      )}
      <StreamerInfo>
        <Name>{name ? name : t('unknown')}</Name>
        <ChannelTitle>{channelTitle}</ChannelTitle>
      </StreamerInfo>
    </PresenterData>
  );
}

export default AuthorInformation;

const PresenterData = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const ImageContainer = styled.div`
  width: 48px;
  position: relative;
`;

const TextContainer = styled.div`
  border-radius: 50%;
  width: 48px;
  height: 48px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  background-color: ${colors.grayLight2};
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

const Image = styled.img`
  border-radius: 50%;
  width: 48px;
  height: 48px;
  object-fit: cover;
  clip-path: polygon(
    100% 100%,
    0% 100%,
    0% 0%,
    74% 0,
    68% 13%,
    68% 17%,
    72% 26%,
    81% 32%,
    90% 32%,
    100% 29%
  );
`;

const Timer = styled.div`
  top: 0;
  right: 0;
  position: absolute;
`;

const StreamerInfo = styled.div`
  margin-left: 12px;
  max-width: 145px;
`;

const Name = styled.span`
  display: block;
  font-size: 10px;
  line-height: 14px;
  color: #fff;
`;

const ChannelTitle = styled.span`
  display: block;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #fff;
`;
