import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
  apiKey: 'AIzaSyBt-8qziyqG-zkI1lsJaOcW32GiYp85NZ4',
  authDomain: 'streaming-bc81d.firebaseapp.com',
  projectId: 'streaming-bc81d',
  storageBucket: 'streaming-bc81d.appspot.com',
  messagingSenderId: '537618151939',
  appId: '1:537618151939:web:17c2e4960f720723dcac36',
  measurementId: 'G-3HQ1LDHCK4',
};

firebase.initializeApp(config);

export const db = firebase.firestore();
