import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as HomeBgLeftSvg } from '../../assets/svg/home-bg-left.svg';
import { ReactComponent as HomeBgRightSvg } from '../../assets/svg/home-bg-right.svg';
import { ReactComponent as HomeBgTopSvg } from '../../assets/svg/home-mobile-bg-top.svg';
import { ReactComponent as HomeBgBottomSvg } from '../../assets/svg/home-mobile-bg-bottom.svg';
import { ReactComponent as LogoSvg } from '../../assets/svg/logo.svg';
import { colors } from '../../constants/theme';

function Home() {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <LeftSvg />
      <RightSvg />
      <TopSvg />
      <BottomSvg />
      <Content>
        <LogoSvg />
        <Title>{t('homeScreen.title')}</Title>
        <MobileTitle>{t('homeScreen.titleMobile')}</MobileTitle>
        <Desc> {t('homeScreen.desc')}</Desc>
        <Copyright>{t('homeScreen.copyright')}</Copyright>
      </Content>
    </Wrapper>
  );
}

export default Home;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
`;

const LeftSvg = styled(HomeBgLeftSvg)`
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  @media (max-width: 1023px) {
    left: -220px;
  }
  @media (max-width: 767px) {
    display: none;
  } ;
`;

const RightSvg = styled(HomeBgRightSvg)`
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  @media (max-width: 1023px) {
    right: -220px;
  }
  @media (max-width: 767px) {
    display: none;
  } ;
`;

const TopSvg = styled(HomeBgTopSvg)`
  display: none;
  position: absolute;
  left: 0;
  top: -20px;
  width: 100%;
  @media (max-width: 767px) {
    display: inline-block;
  } ;
`;

const BottomSvg = styled(HomeBgBottomSvg)`
  display: none;
  position: absolute;
  right: 0;
  bottom: -20px;
  width: 100%;
  @media (max-width: 767px) {
    display: inline-block;
  } ;
`;

const Content = styled.div`
  max-width: 598px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 767px) {
    padding: 0 20px;
    svg {
      width: 133px;
      height: 75px;
    }
  } ;
`;

const Title = styled.span`
  display: inline-block;
  font-size: 40px;
  line-height: 55px;
  font-weight: 700;
  margin: 80px 0 30px;
  @media (max-width: 767px) {
    display: none;
  } ;
`;

const MobileTitle = styled.span`
  display: none;
  font-size: 34px;
  line-height: 46px;
  font-weight: 700;
  margin: 40px 0 16px;
  @media (max-width: 767px) {
    display: inline-block;
  } ;
`;

const Desc = styled.span`
  display: block;
  font-size: 18px;
  line-height: 25px;
  font-weight: 400;
  margin-bottom: 80px;
  @media (max-width: 767px) {
    font-size: 17px;
    line-height: 23px;
    margin-bottom: 40px;
  } ;
`;

const Copyright = styled.span`
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: ${colors.grayMedium};
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 20px;
  } ;
`;
