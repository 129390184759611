import React, { useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { shallowEqual } from 'react-redux';

import { useModal } from '../../../../../../../../components/ModalProvider';
import LoginModal from '../../../../../../../../components/Modal/LoginModal';
import PaymentModal from '../../../../../../../../components/Modal/PaymentModal';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../store/hooks';
import { fetchStreamInfoThunk } from '../../../../../../../../store/streamInfo';

import PaidStream from './components/PaidStream';
import UnpaidStream from './components/UnpaidStream';

type Props = {
  price: number;
  startDate: number;
  hasChannelSubscription: boolean;
};

function GetOnStream({ price, startDate, hasChannelSubscription }: Props) {
  const router = useRouteMatch<{ channelId: string }>();
  const dispatch = useAppDispatch();
  const openModal = useModal();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isShow, setShow] = useState(false);
  const [timeLeft, setTimeLeft] = useState(
    startDate * 1000 - new Date().getTime()
  );

  const { authStatus, errorMessage } = useAppSelector(
    (state) => ({
      authStatus: state.auth.status,
      errorMessage: state.streamInfo.error,
    }),
    shallowEqual
  );

  useEffect(() => {
    function listener(this: Document, event: Event) {
      if ((wrapperRef.current?.getBoundingClientRect().bottom ?? 0) < 0) {
        setShow(true);
      } else {
        setShow(false);
      }
    }
    document.addEventListener('scroll', listener);
    return () => document.removeEventListener('scroll', listener);
  }, [wrapperRef.current]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        const now = new Date().getTime();
        if (startDate * 1000 - now <= 0) {
          dispatch(fetchStreamInfoThunk(router.params.channelId));
          clearInterval(timer);
        } else {
          setTimeLeft(startDate * 1000 - now);
        }
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, []);

  function handleOpenLoginModal() {
    // openModal(LoginModal, {});
    authStatus === 'AUTHORIZED'
      ? openModal(PaymentModal, { channelId: router.params.channelId })
      : openModal(LoginModal, { channelId: router.params.channelId });
  }
  const isStreamNow = new Date(startDate * 1000) < new Date();

  return hasChannelSubscription ? (
    <PaidStream
      timeLeft={timeLeft}
      isShow={isShow}
      handleOpenLoginModal={handleOpenLoginModal}
      ref={wrapperRef}
      isStreamNow={isStreamNow}
    />
  ) : (
    <UnpaidStream
      price={price}
      timeLeft={timeLeft}
      isShow={isShow}
      handleOpenLoginModal={handleOpenLoginModal}
      isStreamNow={isStreamNow}
      isAuthorised={authStatus === 'AUTHORIZED'}
    />
  );
}

export default GetOnStream;
