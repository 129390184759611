import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ModalProps } from '../../ModalProvider';
import { ReactComponent as CloseIcon } from '../../../assets/svg/close.svg';
import { ReactComponent as JoinGreenIcon } from '../../../assets/svg/join-green.svg';
import Button from '../../Button';
import { colors } from '../../../constants/theme';

type Props = ModalProps<{
  sendBroadcastingJoinRequest: () => void;
}>;

function AskJoinModal({ closeModal, innerProps }: Props) {
  const { t } = useTranslation();

  const { sendBroadcastingJoinRequest } = innerProps;

  return (
    <Inner>
      <Close onClick={closeModal} />
      <JoinGreenIconBlock>
        <JoinGreenIcon />
      </JoinGreenIconBlock>
      <Title>{t('joinModal.title')}</Title>
      <Description>{t('joinModal.desc')} </Description>
      <JoinButton
        onClick={() => {
          sendBroadcastingJoinRequest();
          closeModal();
        }}
        variant={'primary'}
        type={'submit'}
      >
        {t('joinModal.askStreaming')}
      </JoinButton>
    </Inner>
  );
}

export default AskJoinModal;

const Inner = styled.div`
  position: relative;
  background-color: #fff;
  z-index: 11;
  max-width: 384px;
  border-radius: 16px;
  padding: 40px 24px 24px;
  overflow: hidden;
  text-align: center;
`;

const Close = styled(CloseIcon)`
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    opacity: 0.6;
  }
`;

const JoinGreenIconBlock = styled.div`
  display: inline-block;
  border-radius: 50%;
  background-color: ${colors.grayLight};
  padding: 28px 32px;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    margin-bottom: 24px;
  } ;
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 8px;
  } ;
`;

const Description = styled.span`
  display: inline-block;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 24px;
  } ;
`;

const JoinButton = styled(Button)`
  text-transform: uppercase;
  width: 100%;
  padding: 16.5px 0;
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 20px;
  } ;
`;
