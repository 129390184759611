import React, { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { fetchStreamInfoThunk } from '../../store/streamInfo';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { StreamInfoPageType } from '../../typings/model';
import Loader from '../../components/Loader';

import Stream from './components/Stream/Stream';
import PreviewStream from './components/PreviewStream/PreviewStream';
import StreamFinished from './components/StreamFinished/StreamFinished';

function StreamingPage() {
  const router = useRouteMatch<{ channelId: string }>();
  const dispatch = useAppDispatch();
  const [streamInfo, setStreamInfo] = useState<StreamInfoPageType | null>(null);

  function getOS() {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

    if (macosPlatforms.indexOf(platform) !== -1) {
      return 'mac';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      return 'ios';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      return 'windows';
    } else if (/Android/.test(userAgent)) {
      return 'android';
    } else if (/Linux/.test(platform)) {
      return 'linux';
    }
    return 'other';
  }

  const isStreamEnd = streamInfo?.finishDate
    ? new Date() > new Date(streamInfo.finishDate * 1000)
    : false;

  const isStreamNow =
    new Date((streamInfo?.startDate ?? 0) * 1000) < new Date() && !isStreamEnd;

  const { status, streamInfoRedux, errorMessage } = useAppSelector(
    (state) => ({
      streamInfoRedux: state.streamInfo.data,
      errorMessage: state.streamInfo.error,
      status: state.streamInfo.status,
    }),
    shallowEqual
  );

  async function getData() {
    await dispatch(fetchStreamInfoThunk(router.params.channelId));
  }

  useEffect(() => {
    const isInstagram = navigator.userAgent.includes('Instagram');
    const isFacebook =
      navigator.userAgent.includes('FBAN') ||
      navigator.userAgent.includes('FBAV');

    switch (getOS()) {
      case 'ios':
        if (isInstagram || isFacebook) {
          window.location.href =
            'ftp://files.devroqapps.com/redirect.html?url=' +
            encodeURIComponent(window.location.href);
        } else {
          getData();
        }
        break;
      case 'android':
        if (isInstagram) {
          window.location.href =
            'https://redirect.streamspace.club/redirect.php?url=' +
            encodeURIComponent(window.location.href);
        } else {
          getData();
        }
        break;
      default:
        getData();
        break;
    }
  }, []);

  useEffect(() => {
    if (streamInfoRedux) {
      setStreamInfo(streamInfoRedux);
    }
  }, [streamInfoRedux]);

  if (status === 'pending') {
    return <Loader />;
  }
  if (streamInfo && streamInfo.hasChannelSubscription && isStreamNow) {
    return <Stream streamInfo={streamInfo} />;
  }
  if (status === 'failed' || isStreamEnd) {
    return <StreamFinished />;
  }
  if (streamInfo) {
    return <PreviewStream streamInfo={streamInfo} />;
  }

  return null;
}

export default StreamingPage;
