import React, { ReactElement } from 'react';
import styled from 'styled-components';

type Props = {
  title: string;
  text: string;
  icon: ReactElement;
};

function RuleCard({ title, text, icon }: Props) {
  return (
    <Container>
      <IconBlock>{icon}</IconBlock>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 24px;
  border-radius: 16px;
  min-height: 240px;
`;

const IconBlock = styled.div`
  margin-bottom: 21.5px;
  @media (max-width: 1023px) {
    margin-bottom: 14.5px;
  }
`;

const Title = styled.span`
  display: block;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    font-size: 17px;
    line-height: 23px;
  }
`;

const Text = styled.span`
  display: block;
  font-size: 16px;
  line-height: 22px;
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 20px;
  }
`;

export default RuleCard;
