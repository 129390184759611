import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ReactGA from 'react-ga';

import StreamingPage from './modules/StreamingPage';
import { ModalProvider } from './components/ModalProvider';
import useFixedVhProperty from './hooks/useFixedVhProperty';
import { EN } from './locales/en';
import { RU } from './locales/ru';
import { checkAuthorizationThunk } from './store/auth';
import { useAppDispatch } from './store/hooks';
import { db } from './config/auth';
import Home from './modules/Home';

function App() {
  const dispatch = useAppDispatch();

  useFixedVhProperty();

  useEffect(() => {
    ReactGA.initialize('UA-47863421-4');
    db.collection('users');
    dispatch(checkAuthorizationThunk());
  }, []);

  i18n.use(initReactI18next).init({
    resources: {
      'en-EN': {
        translation: EN,
      },
      'ru-RU': {
        translation: RU,
      },
    },
    lng: navigator.language,
    fallbackLng: 'en-EN',

    interpolation: {
      escapeValue: false,
    },
  });

  return (
    <div className="App">
      <ModalProvider>
        <Router>
          <Switch>
            <Route path="/:channelId">
              <StreamingPage />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </ModalProvider>
    </div>
  );
}

export default App;
