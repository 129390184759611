import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ModalProps } from '../../ModalProvider';

type Props = ModalProps;

function ReportModal({ closeModal }: Props) {
  const { t } = useTranslation();

  function handleReport() {
    closeModal();
  }

  return (
    <Inner>
      <ButtonsBlock>
        <ReportButton onClick={handleReport}>
          {t('reportModal.reportStream')}
        </ReportButton>
        <CancelButton onClick={closeModal}>
          {t('reportModal.cancel')}
        </CancelButton>
      </ButtonsBlock>
    </Inner>
  );
}

export default ReportModal;

const Inner = styled.div`
  position: relative;
  z-index: 11;
  max-width: calc(384px + 40px);
  border-radius: 16px;
  text-align: center;
  overflow: hidden;
  padding: 0 20px;
  width: 100vw;
`;

const ButtonsBlock = styled.div`
  background-color: #fff;
  border-radius: 16px;
  overflow: hidden;
`;

const ReportButton = styled.button`
  padding: 16px;
  border-bottom: solid 1px #00000010;
  color: #f84c35;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  background-color: #ffffff;
  width: 100%;
  transition: 0.3s;
  &:hover {
    color: #f84c3590;
  }
`;

const CancelButton = styled.button`
  padding: 16px;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  background-color: #ffffff;
  width: 100%;
  transition: 0.3s;
  &:hover {
    opacity: 0.7;
  }
`;
