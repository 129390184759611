import React from 'react';
import styled from 'styled-components';

import HeaderImageUrl from '../../../../assets/images/header.png';

function Header() {
  return <HeaderContainer />;
}

export default Header;

const HeaderContainer = styled.div`
  background-image: url(${HeaderImageUrl});
  height: 144px;
  @media (max-width: 767px) {
    height: 124px;
    background-size: cover;
  } ;
`;
