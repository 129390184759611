import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import firebase from 'firebase';

import { getUserData } from '../services/requests';

import { AppDispatch } from './store';
import { fetchUserDataThunk } from './user';

export type AuthStatus = 'AUTHORIZED' | 'UNAUTHORIZED';

type State = {
  status: AuthStatus;
  error: string | null;
};

const initialState: State = {
  status: 'UNAUTHORIZED',
  error: null,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userAuthorized(state) {
      state.status = 'AUTHORIZED';
    },
    userUnauthorized(state) {
      state.status = 'UNAUTHORIZED';
    },
  },
});

export default slice.reducer;

const { userAuthorized, userUnauthorized } = slice.actions;

export function signInThunk(
  api_token: string
): (dispatch: AppDispatch) => Promise<void> {
  return async (dispatch: AppDispatch) => {
    try {
      window.localStorage.setItem('access_token', api_token);
      dispatch(userAuthorized());
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function logoutThunk(): (dispatch: AppDispatch) => Promise<void> {
  return async (dispatch: AppDispatch) => {
    try {
      window.localStorage.removeItem('access_token');
      return firebase
        .auth()
        .signOut()
        .finally(() => {
          dispatch(userUnauthorized());
        });
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function checkAuthorizationThunk(): (
  dispatch: AppDispatch
) => Promise<void> {
  return async (dispatch) => {
    try {
      const authUser = await dispatch(fetchUserDataThunk());
      if (authUser) {
        dispatch(userAuthorized());
      }
    } catch (error) {}
  };
}
