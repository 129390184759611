import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { colors } from '../../../../../constants/theme';

type Props = {
  name: string;
  image?: string;
  message: string;
};

function UserMessage({ name, message, image }: Props) {
  const { t } = useTranslation();
  const splitName = name ? name.split(' ') : [];
  return (
    <PresenterData>
      {image ? (
        <ImageContainer>
          <Image src={image} />
        </ImageContainer>
      ) : splitName.length ? (
        <TextContainer>
          {splitName[0].substr(0, 1) + splitName[1].substr(0, 1)}
        </TextContainer>
      ) : (
        <TextContainer>{t('unknown').substr(0, 1)}</TextContainer>
      )}
      <StreamerInfo>
        <Name>{name ? name : t('unknown')}</Name>
        <Message>{message}</Message>
      </StreamerInfo>
    </PresenterData>
  );
}

export default UserMessage;

const PresenterData = styled.div`
  display: flex;
  align-items: center;
`;

const ImageContainer = styled.div`
  width: 9.88%;
  min-width: 32px;
`;

const TextContainer = styled.div`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  background-color: ${colors.grayLight2};
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

const Image = styled.img`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  object-fit: cover;
`;

const StreamerInfo = styled.div`
  margin-left: 8px;
  width: calc(90.12% - 13px);
`;

const Name = styled.span`
  display: block;
  font-size: 10px;
  line-height: 14px;
  color: #fff;
`;

const Message = styled.span`
  display: block;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #fff;
  overflow-wrap: break-word;
`;
