import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { usePubNub } from 'pubnub-react';
import { MessageEvent, PresenceEvent } from 'pubnub';
import { useRouteMatch } from 'react-router-dom';
import _ from 'lodash';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { colors } from '../../../../../../constants/theme';
import { ReactComponent as ShareIcon } from '../../../../../../assets/svg/share.svg';
import { ReactComponent as ShareBlackIcon } from '../../../../../../assets/svg/share-black.svg';
import { ReactComponent as JoinIconWhite } from '../../../../../../assets/svg/join-white.svg';
import { ReactComponent as JoinGreenIcon } from '../../../../../../assets/svg/join-green.svg';
import { ReactComponent as JoinPendingIcon } from '../../../../../../assets/svg/join-pending.svg';
import { ReactComponent as JoinAttachedIcon } from '../../../../../../assets/svg/join-attached.svg';
import { ReactComponent as SadSmileIcon } from '../../../../../../assets/svg/sad-smile.svg';
import { ReactComponent as DotsIcon } from '../../../../../../assets/svg/dots.svg';
import { ReactComponent as ArrowIcon } from '../../../../../../assets/svg/arrow.svg';
import ReportModal from '../../../../../../components/Modal/ReportModal';
import { useModal } from '../../../../../../components/ModalProvider';
import AskJoinModal from '../../../../../../components/Modal/AskJoinModal';
import { UserProfileType } from '../../../../../../typings/model';
import { isTouchScreen } from '../../../../../../utils/common';
import { Rtc, StatusBroadcastingType } from '../../Stream';
import Toast from '../Toast';
import UserMessage from '../UserMessage';

import MoreAction from './components/MoreAction';

type MessageType = {
  message: string;
  name: string;
  avatarURL?: string;
};

type UserMetaData = {
  rejectUserID?: string;
  acceptUserID?: string;
  stopBroadcastingUserID?: string;
  user: {
    ID: string;
    name: string;
    email: string;
    avatarURL?: string;
  };
};

type Props = {
  setTotalOccupancy: (totalOccupancy: number) => void;
  joinToBroadcasting: () => void;
  exitFromBroadcasting: () => void;
  setMicrophoneOff: (microphoneOff: boolean) => void;
  setCameraOff: (cameraOff: boolean) => void;
  microphoneOff: boolean;
  cameraOff: boolean;
  agoraUserID: number | string | undefined;
  userData: UserProfileType | null;
  statusBroadcastingRequest: StatusBroadcastingType;
  setStatusBroadcastingRequest: (
    statusBroadcastingRequest: StatusBroadcastingType
  ) => void;
  rtc: Rtc;
};

function Chat({
  setTotalOccupancy,
  joinToBroadcasting,
  exitFromBroadcasting,
  setMicrophoneOff,
  setCameraOff,
  microphoneOff,
  cameraOff,
  agoraUserID,
  userData,
  statusBroadcastingRequest,
  setStatusBroadcastingRequest,
  rtc,
}: Props) {
  const router = useRouteMatch<{ channelId: string }>();
  const openModal = useModal();
  const pubnub = usePubNub();
  const [channels] = useState([router.params.channelId]);
  const { t } = useTranslation();

  const [messages, addMessage] = useState<Array<MessageType>>([]);
  const [message, setMessage] = useState('');

  const [isFocus, setFocus] = useState(false);

  function handleMessage(event: MessageEvent & { userMetadata: UserMetaData }) {
    // console.log('event', event);
    if (
      'Accept broadcasting request' === event.message &&
      event.userMetadata.acceptUserID
    ) {
      if (event.userMetadata.acceptUserID === userData?.firebase_id) {
        joinToBroadcasting();
        setStatusBroadcastingRequest('attached');
      }
    } else if (
      'Stop broadcasting' === event.message &&
      event.userMetadata.stopBroadcastingUserID
    ) {
      if (event.userMetadata.stopBroadcastingUserID === userData?.firebase_id) {
        exitFromBroadcasting();
        setStatusBroadcastingRequest('idle');
      }
    } else if (
      'Reject broadcasting request' === event.message &&
      event.userMetadata.rejectUserID
    ) {
      if (event.userMetadata.rejectUserID === userData?.firebase_id) {
        toast(() => (
          <Toast
            icon={<SadSmileIcon />}
            text={t('chat.dismissFromBroadcasting', {
              authorName: event.userMetadata.user.name,
            })}
          />
        ));
        setStatusBroadcastingRequest('idle');
      }
    } else if (
      ['Remove broadcasting request', 'Send broadcasting request'].includes(
        event.message
      )
    ) {
      return;
    } else {
      const message = {
        ID: event.userMetadata.user.ID,
        message: event.message,
        name: event.userMetadata.user.name,
        avatarURL: event.userMetadata.user.avatarURL,
      };

      addMessage((messages) => [message, ...messages]);
    }
  }

  function handlePresence(event: PresenceEvent) {
    setTotalOccupancy(event.occupancy);
  }

  pubnub.hereNow({ channels: channels }).then((response) => {
    setTotalOccupancy(response.totalOccupancy);
  });
  //
  // pubnub
  //   .history({ channel: channels[0], count: 6, includeMeta: true })
  //   .then((response) => {
  //     response.messages.forEach((message) => {
  //       console.log('message', message);
  //       // addMessage((messages) => [message.entry, ...messages]);
  //     });
  //   });

  useEffect(() => {
    if (userData) {
      pubnub.addListener({
        message: handleMessage,
        presence: handlePresence,
      });
      pubnub.subscribe({ channels, withPresence: true });
    }
  }, [channels, userData]);

  function sendMessage(message: string) {
    if (message) {
      return pubnub.publish({
        channel: channels[0],
        message: message,
        meta: {
          user: {
            ID: userData?.firebase_id,
            name: userData?.name ? userData.name : undefined,
            email: userData?.email,
            avatarURL: userData?.avatarURL ? userData.avatarURL : undefined,
            agoraUserID: agoraUserID,
          },
        },
      });
    }
  }

  function sendBroadcastingJoinRequest() {
    sendMessage('Send broadcasting request')?.then(() => {
      setStatusBroadcastingRequest('pending');
      toast(() => (
        <Toast icon={<JoinGreenIcon />} text={t('chat.broadcastingRequest')} />
      ));
    });
  }

  function removeBroadcastingJoinRequest() {
    sendMessage('Remove broadcasting request')?.then(() => {
      exitFromBroadcasting();
      setStatusBroadcastingRequest('idle');
    });
  }

  function stopBroadcasting() {
    return pubnub
      .publish({
        channel: channels[0],
        message: 'Stop broadcasting',
        meta: {
          stopBroadcastingUserID: userData?.firebase_id,
          user: {
            ID: userData?.firebase_id,
            name: userData?.name ? userData.name : undefined,
            email: userData?.email,
            avatarURL: userData?.avatarURL ? userData.avatarURL : undefined,
            agoraUserID: agoraUserID,
          },
        },
      })
      ?.then(() => {
        setStatusBroadcastingRequest('idle');
      });
  }

  function handleShare() {
    if (isTouchScreen()) {
      navigator.share({ url: window.location.href });
    } else {
      const urlInput = document.body.appendChild(
        document.createElement('input')
      );
      urlInput.value = window.location.href;
      urlInput.focus();
      urlInput.select();
      document.execCommand('copy');
      urlInput.parentNode?.removeChild(urlInput);

      toast(() => (
        <Toast text={t('chat.shareLink')} icon={<ShareBlackIcon />} />
      ));
    }
  }

  function handleOpenReportModal() {
    openModal(ReportModal, {});
  }

  function handleJoin() {
    if (statusBroadcastingRequest === 'idle') {
      openModal(AskJoinModal, { sendBroadcastingJoinRequest });
    } else if (statusBroadcastingRequest === 'pending') {
      removeBroadcastingJoinRequest();
    } else {
      stopBroadcasting();
    }
  }

  function handleInputBlur() {
    setFocus(false);
  }

  function handleInputFocus() {
    setFocus(true);
  }

  function handleInputKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key !== 'Enter') return;
    sendMessage(message)?.then(() => setMessage(''));
  }

  function handleSendButton() {
    sendMessage(message)?.then(() => {
      setMessage('');
      setFocus(false);
    });
  }

  return (
    <ChatContainer>
      <ChatList>
        {messages.map((message, index) => (
          <Message key={`message-${index}`}>
            <UserMessage
              name={message.name}
              message={message.message}
              image={message.avatarURL}
            />
          </Message>
        ))}
      </ChatList>
      <Bottom>
        <InputBlock isFocus={isFocus}>
          <MessageInput
            type="text"
            placeholder={t('chat.addComment')}
            value={_.truncate(message, {
              length: 200,
              omission: '',
            })}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onKeyPress={handleInputKeyPress}
            onChange={(e) => setMessage(e.target.value)}
          />
          <ReportButton isFocus={isFocus} onClick={handleOpenReportModal}>
            <DotsIconBlock>
              <DotsIcon />
            </DotsIconBlock>
          </ReportButton>
          <SendButton isMessage={!!message} onClick={handleSendButton}>
            <ArrowIconBlock>
              <ArrowIcon />
            </ArrowIconBlock>
          </SendButton>
        </InputBlock>
        <JoinIconBlock onClick={handleShare} isFocus={isFocus}>
          <ShareIcon />
        </JoinIconBlock>
        {statusBroadcastingRequest === 'idle' ? (
          <JoinIconBlock isFocus={isFocus} onClick={handleJoin}>
            <JoinIconWhite />
          </JoinIconBlock>
        ) : statusBroadcastingRequest === 'pending' ? (
          <JoinIconBlock isFocus={isFocus} onClick={handleJoin}>
            <JoinPendingIcon />
          </JoinIconBlock>
        ) : (
          <JoinIconBlock isFocus={isFocus}>
            <JoinAttachedIcon onClick={handleJoin} />
            <MoreActionBlock>
              <MoreAction
                rtc={rtc}
                setMicrophoneOff={setMicrophoneOff}
                setCameraOff={setCameraOff}
                microphoneOff={microphoneOff}
                cameraOff={cameraOff}
              />
            </MoreActionBlock>
          </JoinIconBlock>
        )}
      </Bottom>
    </ChatContainer>
  );
}

export default Chat;

const ChatContainer = styled.div`
  padding: 20px;
  transition: 0.3s;
  overflow: hidden;
`;

const ChatList = styled.ul`
  position: relative;
  max-width: 70.8%;
  mask-image: linear-gradient(
    360deg,
    #c4c4c4 49.83%,
    rgba(196, 196, 196, 0) 100%
  );
  display: flex;
  flex-direction: column-reverse;
  height: 284px;
`;

const Message = styled.li`
  margin-bottom: 18px;
`;

const InputBlock = styled.div<{ isFocus: boolean }>`
  display: block;
  width: 70.8%;
  min-width: ${(props) => (props.isFocus ? '100%' : '0')};
  transition: 0.3s;
  position: relative;
`;

const ReportButton = styled.button<{ isFocus: boolean }>`
  position: absolute;
  right: 18px;
  top: 12px;
  transition: 0.3s;
  cursor: pointer;

  ${(props) =>
    props.isFocus
      ? css`
          opacity: 0;
          pointer-events: none;
        `
      : null}
`;

const DotsIconBlock = styled.div`
  display: inline-block;
  padding-bottom: 4px;
`;

const SendButton = styled.button<{ isMessage: boolean }>`
  position: absolute;
  top: 6px;
  right: 6px;
  pointer-events: none;
  opacity: 0;
  transition: 0.3s;
  cursor: pointer;

  ${(props) =>
    props.isMessage
      ? css`
          opacity: 1;
          pointer-events: auto;
        `
      : null}
`;

const ArrowIconBlock = styled.div`
  display: inline-block;
  border-radius: 50%;
  background-color: ${colors.green};
  padding: 6px 6px 2px;
`;

const Bottom = styled.div`
  display: flex;
`;

const MessageInput = styled.input`
  font-size: 15px;
  line-height: 20px;
  border-radius: 33px;
  border: none;
  color: #ffffff;
  background-color: #ffffff15;
  padding: 14px 52px 14px 16px;
  caret-color: ${colors.green};
  width: 100%;
  &::placeholder {
    color: #ffffff50;
  }
`;

const JoinIconBlock = styled.div<{ isFocus: boolean }>`
  display: inline-block;
  margin-left: 8px;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: #ffffff15;
  transition: 0.3s;
  position: relative;

  ${(props) =>
    props.isFocus
      ? css`
          opacity: 0;
          pointer-events: none;
        `
      : null}

  :hover {
    cursor: pointer;
  }
`;

const MoreActionBlock = styled.div`
  display: inline-block;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  padding: 26px 11px;
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  cursor: default;
  transition: 0.3s;
  bottom: calc(100% + 16px);
  z-index: 0;
  svg {
    width: 24px;
    height: 24px;
  }
`;
