import axios from 'axios';

const request = axios.create({
  baseURL: 'https://us-central1-streaming-bc81d.cloudfunctions.net/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export { request };

request.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem('access_token');
    if (token) {
      config.headers['x-api-token'] = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
