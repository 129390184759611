import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StreamInfoPageType } from '../typings/model';
import { getStreamInfoPage } from '../services/requests';

import { AppDispatch } from './store';

type State = {
  status: 'idle' | 'pending' | 'success' | 'failed';
  data: StreamInfoPageType | null;
  error: string | null;
};

const initialState: State = {
  status: 'idle',
  data: null,
  error: null,
};

const slice = createSlice({
  name: 'streamInfo',
  initialState,
  reducers: {
    fetchStreamInfoLoading: (state) => {
      state.status = 'pending';
      state.error = null;
    },
    fetchStreamInfoSuccess: (
      state,
      action: PayloadAction<StreamInfoPageType>
    ) => {
      state.status = 'success';
      state.data = action.payload;
    },
    fetchStreamInfoError: (state, action: PayloadAction<string>) => {
      state.status = 'failed';
      state.data = null;
      state.error = action.payload;
    },
  },
});

export default slice.reducer;

const {
  fetchStreamInfoLoading,
  fetchStreamInfoSuccess,
  fetchStreamInfoError,
} = slice.actions;

export function fetchStreamInfoThunk(
  channelId: string | undefined
): (dispatch: AppDispatch) => Promise<StreamInfoPageType | null> {
  return async (dispatch: AppDispatch) => {
    dispatch(fetchStreamInfoLoading());
    try {
      const response = await getStreamInfoPage(channelId);

      dispatch(fetchStreamInfoSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(fetchStreamInfoError(error.message));
      return null;
    }
  };
}
