import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ClockSvg } from '../../../../../../../assets/svg/clock.svg';
import { ReactComponent as CharitySvg } from '../../../../../../../assets/svg/charity.svg';
import { colors } from '../../../../../../../constants/theme';

type Props = {
  author: {
    id: string;
    avatarURL: string;
    name: string;
  };
  title: string;
  duration: number;
  description: string;
  moneyForCharity: boolean;
};

function AboutStream({
  title,
  description,
  author,
  duration,
  moneyForCharity,
}: Props) {
  const { t } = useTranslation();

  const splitName = author.name ? author.name.split(' ') : [];

  function getTimeStream() {
    if (new Date(duration * 1000).getUTCHours() >= 1) {
      return `${new Date(duration * 1000).getUTCHours()} ${t(
        'aboutStream.hoursStream'
      )}`;
    }
    return `${new Date(duration * 1000).getUTCMinutes()} ${t(
      'aboutStream.minutesStream'
    )}`;
  }

  return (
    <Container>
      <Title>{title}</Title>
      <LengthStream>
        <ClockSvg />
        {getTimeStream()}
      </LengthStream>
      {moneyForCharity ? (
        <CharityStream>
          <CharitySvg />
          {t('aboutStream.moneyForCharity')}
        </CharityStream>
      ) : null}
      <Description>{description}</Description>
      <Divider />
      <PresenterData>
        {author.avatarURL ? (
          <ImageContainer>
            <Image src={author.avatarURL} />
          </ImageContainer>
        ) : splitName.length ? (
          <TextContainer>
            {splitName[0].substr(0, 1) + (splitName[1]?.substr(0, 1) ?? '')}
          </TextContainer>
        ) : (
          <TextContainer>{t('unknown').substr(0, 1)}</TextContainer>
        )}
        <StreamerInfo>
          <Name>{author.name ? author.name : t('unknown')}</Name>
          {/*<Website>@Copyblogger.com</Website>*/}
        </StreamerInfo>
      </PresenterData>
    </Container>
  );
}

const Container = styled.div`
  width: 54.77%;
  @media (max-width: 1023px) {
    width: 100%;
    text-align: center;
  } ;
`;

const Title = styled.h1`
  font-weight: 600;
  font-size: 40px;
  line-height: 55px;
  margin: 0 0 20px;
  @media (max-width: 1023px) {
    margin-bottom: 25px;
  }
  @media (max-width: 767px) {
    font-size: 26px;
    line-height: 36px;
  }
`;

const QuickInfo = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: inline-block;
  margin-bottom: 20px;
  svg {
    vertical-align: top;
    margin-right: 10px;
  }
  @media (max-width: 1023px) {
    margin-bottom: 24px;
    letter-spacing: -0.5px;
    svg {
      display: block;
      margin: auto auto 6px;
    }
  }
`;

const LengthStream = styled(QuickInfo)``;

const CharityStream = styled(QuickInfo)`
  color: ${colors.green};
  margin-left: 30px;
`;

const Description = styled.span`
  display: block;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 40px;
  @media (max-width: 1023px) {
    margin-bottom: 0;
  }
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 20px;
  }
`;

const Divider = styled.div`
  display: none;
  background: #000000;
  opacity: 0.1;
  border-radius: 22px;
  height: 1px;
  width: 49.2%;
  min-width: 184px;
  margin: 48px auto 27px;
  @media (max-width: 1023px) {
    display: inline-block;
  }
`;

const PresenterData = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1023px) {
    display: block;
  }
`;

const ImageContainer = styled.div`
  @media (max-width: 1023px) {
    margin-bottom: 8px;
  }
`;

const TextContainer = styled.div`
  border-radius: 50%;
  width: 48px;
  height: 48px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  background-color: ${colors.grayLight2};
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  @media (max-width: 1023px) {
    margin-bottom: 8px;
  }
`;

const Image = styled.img`
  border-radius: 50%;
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin: auto;
`;

const StreamerInfo = styled.div`
  margin-left: 20px;
  @media (max-width: 1023px) {
    margin-left: 0;
    letter-spacing: -0.5px;
  }
`;

const Name = styled.span`
  display: block;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
`;

const Website = styled.span`
  display: block;
  font-size: 14px;
  line-height: 19px;
  color: ${colors.grayMedium};
`;

export default AboutStream;
