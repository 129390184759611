import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { useTranslation } from 'react-i18next';

import ContentContainer from '../../../../../../components/ContentContainer';
import NextArrowSvgUrl from '../../../../../../assets/svg/nextArrow.svg';
import { ReactComponent as RespectSvg } from '../../../../../../assets/svg/respect.svg';
import { ReactComponent as HeadSvg } from '../../../../../../assets/svg/head.svg';
import { ReactComponent as EmpathySvg } from '../../../../../../assets/svg/empathy.svg';
import { ReactComponent as JoinBlackSvg } from '../../../../../../assets/svg/join-black.svg';

import RuleCard from './components/RuleCard';

SwiperCore.use([Navigation]);

function Rules() {
  const { t } = useTranslation();

  const RULES = [
    {
      icon: <HeadSvg />,
      title: t('rules.cart.cartTitle1'),
      text: t('rules.cart.cartDesc1'),
    },
    {
      icon: <JoinBlackSvg />,
      title: t('rules.cart.cartTitle2'),
      text: t('rules.cart.cartDesc2'),
    },
    {
      icon: <RespectSvg />,
      title: t('rules.cart.cartTitle3'),
      text: t('rules.cart.cartDesc3'),
    },
    {
      icon: <EmpathySvg />,
      title: t('rules.cart.cartTitle4'),
      text: t('rules.cart.cartDesc4'),
    },
      //TODO: uncomment me if you need more slides
    // {
    //   icon: <HeadSvg />,
    //   title: t('rules.cart.cartTitle5'),
    //   text: t('rules.cart.cartDesc5'),
    // },
  ];

  return (
    <Container>
      <ContentContainer>
        <Inner>
          <Top>
            <Title>{t('rules.rulesOfStream')}</Title>
            <SwiperButtons>
              <PrevButton className="swiper-button-prev" />
              <NextButton className="swiper-button-next" />
            </SwiperButtons>
          </Top>
          <Swiper
            spaceBetween={8}
            slidesPerView={1.75}
            allowTouchMove={true}
            breakpoints={{
              1024: {
                spaceBetween: 20,
                slidesPerView: 4,
                allowTouchMove: false,
              },
              768: {
                spaceBetween: 20,
                slidesPerView: 3,
              },
            }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
          >
            {RULES.map((rule, index) => (
              <SwiperSlide key={index}>
                <RuleCard
                  title={rule.title}
                  text={rule.text}
                  icon={rule.icon}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Inner>
      </ContentContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const Inner = styled.div`
  width: 100%;
  padding: 80px 0;
  @media (max-width: 1023px) {
    padding: 40px 0;
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SwiperButtons = styled.div`
  position: relative;
  display: flex;
  width: 82px;
  @media (max-width: 767px) {
    display: none;
  }
`;

const PrevButton = styled.div`
  height: 16px;
  left: 0;
  :after {
    content: url(${NextArrowSvgUrl});
    transform: rotateY(180deg);
    font-size: unset;
  }
`;

const NextButton = styled.div`
  height: 16px;
  right: 6px;
  :after {
    content: url(${NextArrowSvgUrl});
    font-size: unset;
  }
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 30px;
  @media (max-width: 1023px) {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 24px;
  }
`;

export default Rules;
