import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { colors } from '../../../../../../../../../constants/theme';
import Button from '../../../../../../../../../components/Button';
import ContentContainer from '../../../../../../../../../components/ContentContainer';
import { ReactComponent as Streaming1GraySvg } from '../../../../../../../../../assets/svg/streaming-1-gray.svg';
import { ReactComponent as Streaming1GreenSvg } from '../../../../../../../../../assets/svg/streaming-1-green.svg';
import { ReactComponent as Streaming2GraySvg } from '../../../../../../../../../assets/svg/streaming-2-gray.svg';
import { ReactComponent as Streaming2GreenSvg } from '../../../../../../../../../assets/svg/streaming-2-green.svg';
import { timeLeftToNormalTimeFormat } from '../../../../../../../../../utils/common';

type Props = {
  price: number;
  timeLeft: number;
  isShow: boolean;
  handleOpenLoginModal: () => void;
  isStreamNow: boolean;
  isAuthorised: boolean;
};

const UnpaidStream = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      price,
      timeLeft,
      isShow,
      handleOpenLoginModal,
      isStreamNow,
      isAuthorised,
    }: Props,
    wrapperRef: React.Ref<HTMLDivElement>
  ) => {
    const { t } = useTranslation();

    return (
      <Container>
        <Wrapper ref={wrapperRef}>
          <Top isStream={isStreamNow}>
            <IconBlock>
              {isStreamNow ? <Streaming1GreenSvg /> : <Streaming1GraySvg />}
            </IconBlock>
            <Start isStream={isStreamNow}>
              {isStreamNow ? <Streaming2GreenSvg /> : <Streaming2GraySvg />}
              {isStreamNow
                ? t(`getOnStream.streamingNow`)
                : `${t('getOnStream.startsIn')} ` +
                  timeLeftToNormalTimeFormat(timeLeft)}
            </Start>
          </Top>
          {!isStreamNow ? <Divider /> : null}
          <StreamSign>
            <PriceBlock>
              <Price>${price}</Price>
              <Term
                href="https://appyfurious.com/terms-of-service"
                target="_blank"
              >
                {t('getOnStream.termsOfUse')}
              </Term>
            </PriceBlock>
            <LogInButton variant={'primary'} onClick={handleOpenLoginModal}>
              {t(isAuthorised ? 'getOnStream.pay' : 'getOnStream.signMeUp')}
            </LogInButton>
          </StreamSign>
        </Wrapper>
        <FixedWrapper isShow={isShow}>
          <ContentContainer>
            <HeaderInner>
              <HeaderStart>
                {isStreamNow ? <Streaming2GreenSvg /> : <Streaming2GraySvg />}
                {t('getOnStream.startsIn')}{' '}
                {timeLeftToNormalTimeFormat(timeLeft)}
              </HeaderStart>
              <HeaderStreamSign>
                <PriceBlock>
                  <Price>${price}</Price>
                  <Term
                    href={'https://appyfurious.com/terms-of-service'}
                    target="_blank"
                  >
                    {t('getOnStream.termsOfUse')}
                  </Term>
                </PriceBlock>
                <LogInButton variant={'primary'} onClick={handleOpenLoginModal}>
                  {t(isAuthorised ? 'getOnStream.pay' : 'getOnStream.signMeUp')}
                </LogInButton>
              </HeaderStreamSign>
            </HeaderInner>
          </ContentContainer>
        </FixedWrapper>
      </Container>
    );
  }
);

const Container = styled.div``;

const Wrapper = styled.div`
  border: 1px solid ${colors.grayLight2};
  border-radius: 16px;
  width: 100%;
  overflow: hidden;
  @media (max-width: 1023px) {
    border: none;
    padding: 0;
    width: auto;
  } ;
`;

const Top = styled.div<{ isStream: boolean }>`
  padding: 24px 24px 24px;
  text-align: center;
  ${(props) =>
    props.isStream
      ? css`
          background-color: rgba(0, 179, 73, 0.1);
          color: ${colors.green};
        `
      : null};
  @media (max-width: 1023px) {
    border: none;
    padding: 0;
    margin-bottom: 24px;
    ${(props) =>
      props.isStream
        ? css`
            background-color: unset;
          `
        : null};
  } ;
`;

const Divider = styled.div`
  background: #000000;
  opacity: 0.1;
  border-radius: 22px;
  height: 1px;
  width: 88.1%;
  margin: 0 auto;
  @media (max-width: 1023px) {
    display: none;
  }
`;

const IconBlock = styled.div`
  svg {
    margin-bottom: 5px;
  }
  @media (max-width: 1023px) {
    display: none;
  } ;
`;

const Start = styled.div<{ isStream?: boolean }>`
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  svg {
    display: none;
  }
  @media (max-width: 1023px) {
    display: inline-block;
    background: #f3f3f3;
    border-radius: 22px;
    padding: 10px 16px;
    ${(props) =>
      props.isStream
        ? css`
            background-color: rgba(0, 179, 73, 0.1);
          `
        : null};
    svg {
      display: inline-block;
      margin-right: 8px;
      margin-top: -4px;
      vertical-align: middle;
    }
  }
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 20px;
  } ;
`;

const StreamSign = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 24px;
  @media (max-width: 1023px) {
    z-index: 100;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    background-color: #ffffff;
    border-top: 1px solid ${colors.grayLight2};
  } ;
`;

const PriceBlock = styled.div`
  margin-right: 23px;
`;

const Price = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 3px;
`;

const Term = styled.a`
  font-size: 14px;
  line-height: 19px;
  text-decoration: underline;
  color: ${colors.grayMedium};
`;

const LogInButton = styled(Button)`
  text-transform: uppercase;
  @media (max-width: 1023px) {
    font-size: 15px;
    line-height: 0;
    padding: 0 40.5px;
  } ;
`;

const FixedWrapper = styled.div<{ isShow: boolean }>`
  position: fixed;
  top: ${(props) => (props.isShow ? 0 : -150)}px;
  left: 0;
  right: 0;
  background-color: #fff;
  border-bottom: 1px solid ${colors.grayLight2};
  padding: 12.5px 0;
  transition: 0.3s;
  z-index: 100;
  @media (max-width: 1023px) {
    display: none;
  } ;
`;

const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderStart = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  svg {
    margin-right: 15px;
    vertical-align: bottom;
  }
`;

const HeaderStreamSign = styled.div`
  display: flex;
  justify-content: space-between;
`;
export default UnpaidStream;
