import React, { useEffect } from 'react';
import styled from 'styled-components';
import { shallowEqual } from 'react-redux';
import ReactGA from 'react-ga';
import { useRouteMatch } from 'react-router-dom';

import Layout from '../../../../components/Layout';
import { colors } from '../../../../constants/theme';
import { StreamInfoPageType } from '../../../../typings/model';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { logoutThunk } from '../../../../store/auth';

import Hero from './components/Hero/Hero';
import Rules from './components/Rules/Rules';
import Members from './components/Members/Members';

type Props = {
  streamInfo: StreamInfoPageType;
};
function PreviewStream({ streamInfo }: Props) {
  const router = useRouteMatch<{ channelId: string }>();

  const dispatch = useAppDispatch();
  const { authStatus, errorMessage } = useAppSelector(
    (state) => ({
      authStatus: state.auth.status,
      errorMessage: state.streamInfo.error,
    }),
    shallowEqual
  );

  useEffect(() => {
    ReactGA.event({
      category: 'Page',
      action: 'View',
      label: router.params.channelId,
    });
  }, []);

  function logout() {
    dispatch(logoutThunk());
  }

  return (
    <Layout>
      {authStatus === 'AUTHORIZED' ? (
        <LogoutButton onClick={logout}>I'm a hidden Logout button</LogoutButton>
      ) : null}
      <Container>
        <Hero
          author={streamInfo.author}
          title={streamInfo.title}
          finishDate={streamInfo.finishDate}
          startDate={streamInfo.startDate}
          description={streamInfo.description}
          price={streamInfo.price}
          hasChannelSubscription={streamInfo.hasChannelSubscription}
          moneyForCharity={streamInfo.moneyForCharity}
        />
        <Inner>
          <Rules />
          {streamInfo.subscribers.length ? (
            <Members memberList={streamInfo.subscribers} />
          ) : null}
        </Inner>
      </Container>
    </Layout>
  );
}

const Container = styled.div`
  width: 100%;
`;

const Inner = styled.div`
  width: 100%;
  background-color: ${colors.grayLight};
  @media (max-width: 1023px) {
    padding-bottom: 123px;
  }
`;

export default PreviewStream;

const LogoutButton = styled.button`
  background: deeppink;
  border-radius: 30px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  width: 100%;
  padding: 16px 0;
  transition: 0.3s;
  display: none;
  svg {
    vertical-align: bottom;
    margin-right: 12px;
  }
  &:hover {
    opacity: 0.7;
  }
  @media (max-width: 767px) {
    font-size: 15px;
  } ;
`;
