import React from 'react';

export function createNamedContext<ContextValueType>(
  name: string,
  defaultValue: ContextValueType
): React.Context<ContextValueType> {
  const Ctx = React.createContext<ContextValueType>(defaultValue);
  Ctx.displayName = name;
  return Ctx;
}

/**
 * Reference:
 * https://gist.github.com/sw-yx/f18fe6dd4c43fddb3a4971e80114a052#file-createctx-nonullcheck-tsx-L3-L11
 */
export function createContextHookWithProvider<A>(
  name: string,
  defaultValue?: A
) {
  const context = createNamedContext<A | undefined>(name, defaultValue);
  function useCtx() {
    const ctx = React.useContext(context);
    if (!ctx) throw new Error('useCtx must be inside a Provider with a value');
    return ctx;
  }
  return [useCtx, context.Provider] as const;
}

export function getMinutes(time: number): number {
  return time % 60;
}

export function getHours(time: number): number {
  return (time - getMinutes(time)) / 60;
}

export function withZero(number: number): string {
  const stringNumber = String(number);
  if (stringNumber.length < 2) {
    return '0' + stringNumber;
  }
  return stringNumber;
}

export function timeLeftToNormalTimeFormat(timeLeft: number) {
  const days = new Date(timeLeft).getUTCDate() - 1;
  const hours = new Date(timeLeft).getUTCHours();
  const minutes = new Date(timeLeft).getUTCMinutes();
  const seconds = new Date(timeLeft).getUTCSeconds();
  if (days && days > 0) {
    return (
      withZero(days) +
      ':' +
      withZero(hours) +
      ':' +
      withZero(minutes) +
      ':' +
      withZero(seconds)
    );
  }
  return withZero(hours) + ':' + withZero(minutes) + ':' + withZero(seconds);
}

export function isTouchScreen(): boolean {
  if ('maxTouchPoints' in navigator) {
    return navigator.maxTouchPoints > 0;
  } else if ('msMaxTouchPoints' in navigator) {
    // @ts-ignore
    return navigator.msMaxTouchPoints > 0;
  } else {
    // @ts-ignore
    const UA = navigator.userAgent;
    return (
      /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
      /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
    );
  }
}
