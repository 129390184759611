import firebase from 'firebase';
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import ReactGA from 'react-ga';

import { createToken } from '../../../services/requests';
import { AppDispatch } from '../../../store/store';
import { signInThunk } from '../../../store/auth';

function login(
  credential: firebase.auth.AuthCredential,
  dispatch: AppDispatch
) {
  return firebase
    .auth()
    .signInWithCredential(credential)
    .then((result) => {
      return createToken(result.user?.uid).then((result) => {
        dispatch(signInThunk(result.data.api_token));
      });
    })
    .catch((error) => {
      console.error('firebase sign in error', error);
    });
}

export function loginGoogle(
  response: GoogleLoginResponse | GoogleLoginResponseOffline,
  setLoading: (loading: boolean) => void,
  dispatch: AppDispatch
) {
  if ('isSignedIn' in response && response.isSignedIn) {
    const provider = firebase.auth.GoogleAuthProvider;

    return login(provider.credential(response.tokenId), dispatch).catch(
      (error) => {
        console.error('error', error);
        setLoading(false);
      }
    );
  } else {
    console.error('error', response);
  }
}

export async function loginApple(
  setLoading: (loading: boolean) => void,
  dispatch: AppDispatch
) {
  try {
    const provider = new firebase.auth.OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        return createToken(result.user?.uid).then((result) => {
          dispatch(signInThunk(result.data.api_token));
        });
      })
      .catch((error) => {
        console.error('firebase sign in error', error);
        setLoading(false);
      });
  } catch (error) {
    console.error('Apple error', error);
    setLoading(false);
  }
}

function getFacebookUser(facebookAccessToken: string, dispatch: AppDispatch) {
  return login(
    firebase.auth.FacebookAuthProvider.credential(facebookAccessToken),
    dispatch
  );
}

export function loginFacebook(
  setLoading: (loading: boolean) => void,
  dispatch: AppDispatch,
  channelId: string
) {
  // @ts-ignore
  return window.FB.getLoginStatus(function (responseStatus) {
    if (responseStatus.status === 'connected') {
      getFacebookUser(responseStatus.authResponse.accessToken, dispatch)
        .then(() => {
          ReactGA.event({
            category: 'User',
            action: 'Login',
            label: 'Facebook',
            dimension1: channelId,
          });
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    } else {
      // @ts-ignore
      window.FB.login(
        // @ts-ignore
        function (responseLogin) {
          if (responseLogin.authResponse) {
            getFacebookUser(responseLogin.authResponse.accessToken, dispatch)
              .then(() => {
                ReactGA.event({
                  category: 'User',
                  action: 'Login',
                  label: 'Facebook',
                  dimension1: channelId,
                });
              })
              .catch((error) => {
                console.error(error);
                setLoading(false);
              });
          } else {
            console.error('User cancelled login or did not fully authorize.');
            setLoading(false);
          }
        },
        { scope: 'public_profile,email' }
      );
    }
  });
}
