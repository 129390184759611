import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CheckGrayIcon } from '../../../../assets/svg/check-gray.svg';
import { colors } from '../../../../constants/theme';

function StreamFinished() {
  const { t } = useTranslation();

  return (
    <Content>
      <IconBlock>
        <CheckGrayIcon />
      </IconBlock>
      <Title>{t('streamFinished.title')}</Title>
      <Description>{t('streamFinished.desc')}</Description>
    </Content>
  );
}

const Content = styled.div`
  top: 50%;
  right: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  text-align: center;
  width: 100%;
`;

const IconBlock = styled.div`
  background-color: #f3f3f3;
  margin-bottom: 30px;
  border-radius: 50%;
  display: inline-block;
  padding: 40px 36px 34px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 27px;
  } ;
`;

const Description = styled.span`
  display: block;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.grayMedium};
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 20px;
    max-width: 284px;
    margin: 0 auto;
  } ;
`;

export default StreamFinished;
