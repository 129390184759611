import { EN } from './en';

export const RU: typeof EN = {
  getOnStream: {
    joinIn: 'Присоединиться',
    haveAccess: 'Поздравляем, вам открыт доступ!',
    startsIn: 'Начало через',
    streamingNow: 'В эфире',
    termsOfUse: 'Условия использования',
    signMeUp: 'Подписаться',
    pay: 'Оплатить',
  },

  aboutStream: {
    hoursStream: 'часов в эфире',
    minutesStream: 'минут в эфире',
    moneyForCharity: 'Деньги на благотворительность',
  },

  stream: {
    tapToUnmute: 'Tap to unmute',
    accessError: 'Предоставьте доступ чтобы продолжить',
  },

  rules: {
    rulesOfStream: 'Правила стрима',
    cart: {
      cartTitle1: 'Будь собой',
      cartDesc1: 'Качество стрима зависит от поведения аудитории.',
      cartTitle2: 'Присоединиться к видеостриму',
      cartDesc2: 'Качество стрима зависит от поведения аудитории.',
      cartTitle3: 'Уважай других',
      cartDesc3: 'Это относится к каждому, всегда',
      cartTitle4: 'Стремись к эмпатии и понимаю',
      cartDesc4: 'Это относится к каждому, всегда',
      cartTitle5: 'Правила стрима',
      cartDesc5: 'Правила стрима',
    },
  },

  chat: {
    broadcastingRequest:
      'Вы отправили запрос на доступ к стриму. Дождитесь действия организатора.',
    shareLink: 'Поделиться ссылкой из буфера обмена',
    addComment: 'Комментировать...',
    dismissFromBroadcasting:
      '{{authorName}} отказал вам в запросе присоединиться к спикерам',
  },

  streamFinished: {
    title: 'Stream finished',
    desc: 'Stay tuned and join new streams whenever you want',
  },

  joinModal: {
    title: 'Присоединиться к спикерам?',
    desc: 'Спикеры увидят, что вы хотели бы поделиться своим мнением',
    askStreaming: 'Хочу стать спикером',
  },

  loginModal: {
    title: 'Добро пожаловать в стрим!',
    desc:
      'Пожалуйста, войдите в свой аккаунт и наслаждайтесь стримом с нашей платформы',
    appleButton: 'Войти с Apple',
    facebookButton: 'Войти с Facebook',
    googleButton: 'Войти с Google',
  },

  paymentModal: {
    title: 'Оплата',
    desc:
      'Введите данные своей кредитной карты и завершите оплату, чтобы получить доступ к стриму.',
    firstName: 'Имя',
    lastName: 'Фамилия',
    cardNumber: 'Номер карты',
    expiresOn: 'Срок действия',
    completePayment: 'Оплатить',
    note:
      'При добавлении новой карты с неё будет списана и затем возвращена сумма равная 1$. Это необходимо только один раз для подтверждения вашей карты. Возврат будет произведен в течении нескольких дней или часов, в зависимости от банка.\n',
  },

  reportModal: {
    reportStream: 'Пожаловаться',
    cancel: 'Отмена',
  },

  pleaseContactSupport: 'Пожалуйста, свяжитесь с командой поддержки',

  members: 'Участтники',

  unknown: 'Неизвестно',

  homeScreen: {
    title:
      'Видеостриминг-платформа нового поколения, созданная для инфлюенсеров и контент-мейкеров',
    titleMobile: 'Видеостриминг-платформа нового поколения',
    desc:
      'Проводите платные эфиры для аудитории до 1 млн человек, привлекайте пользователей из любых соц сетей. Зарабатывайте на ваших эфирах, поддерживайте благотворительные фонды или проводите полностью благотворительные эфиры.',
    copyright: 'Copyright 2021 Все права защищены',
  },
};
