import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { colors } from '../../../../../../../../../constants/theme';
import ContentContainer from '../../../../../../../../../components/ContentContainer';
import { ReactComponent as Check } from '../../../../../../../../../assets/svg/check.svg';
import { ReactComponent as Streaming2GraySvg } from '../../../../../../../../../assets/svg/streaming-2-gray.svg';
import { ReactComponent as Streaming2GreenSvg } from '../../../../../../../../../assets/svg/streaming-2-green.svg';
import {
  timeLeftToNormalTimeFormat,
  withZero,
} from '../../../../../../../../../utils/common';

type Props = {
  timeLeft: number;
  isShow: boolean;
  handleOpenLoginModal: () => void;
  isStreamNow: boolean;
};

const PaidStream = React.forwardRef<HTMLDivElement, Props>(
  (
    { timeLeft, isShow, handleOpenLoginModal, isStreamNow }: Props,
    wrapperRef: React.Ref<HTMLDivElement>
  ) => {
    const { t } = useTranslation();

    return (
      <Container>
        <Wrapper ref={wrapperRef}>
          <Top>
            <IconBlock>
              <Check />
            </IconBlock>
            <StartMobile isStream={isStreamNow}>
              {isStreamNow ? <Streaming2GreenSvg /> : <Streaming2GraySvg />}
              {t('getOnStream.joinIn')} {timeLeftToNormalTimeFormat(timeLeft)}
            </StartMobile>
          </Top>
          <StreamSign>
            <TextContent>
              <Start>
                {t('getOnStream.joinIn')} {timeLeftToNormalTimeFormat(timeLeft)}
              </Start>
              <Desc>{t('getOnStream.haveAccess')}</Desc>
            </TextContent>
            <IconBlockMobile>
              <Check />
            </IconBlockMobile>
          </StreamSign>
        </Wrapper>
        <FixedWrapper isShow={isShow}>
          <ContentContainer>
            <HeaderInner>
              <HeaderStart>
                {isStreamNow ? <Streaming2GreenSvg /> : <Streaming2GraySvg />}
                {t('getOnStream.startsIn')}{' '}
                {timeLeftToNormalTimeFormat(timeLeft)}
              </HeaderStart>
              <HeaderStreamSign>
                <Term
                  href={'https://appyfurious.com/terms-of-service'}
                  target="_blank"
                >
                  {t('getOnStream.termsOfUse')}
                </Term>
              </HeaderStreamSign>
            </HeaderInner>
          </ContentContainer>
        </FixedWrapper>
      </Container>
    );
  }
);

const Container = styled.div``;

const Wrapper = styled.div`
  border: 1px solid ${colors.grayLight2};
  background-color: ${colors.green};
  border-radius: 16px;
  width: 100%;
  overflow: hidden;
  padding: 17px 76px 42px;
  @media (max-width: 1023px) {
    border: none;
    padding: 0;
    width: auto;
    background-color: unset;
  } ;
`;

const Top = styled.div`
  padding: 24px 24px 24px;
  text-align: center;

  @media (max-width: 1023px) {
    border: none;
    padding: 0;
    margin-bottom: 24px;
  } ;
`;

const IconBlock = styled.div`
  background-color: #0bc958;
  display: inline-block;
  border-radius: 50%;
  padding: 32.5px 28px 25.5px;
  @media (max-width: 1023px) {
    display: none;
  } ;
`;

const Start = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #fff;
  display: block;
  margin-bottom: 2px;
  @media (max-width: 1023px) {
    display: inline-block;
    border-radius: 22px;
  }
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 20px;
  } ;
`;

const StartMobile = styled.div<{ isStream: boolean }>`
  display: none;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  background: #f3f3f3;
  border-radius: 22px;
  padding: 10px 16px;
  ${(props) =>
    props.isStream
      ? css`
          background-color: rgba(0, 179, 73, 0.1);
          color: ${colors.green};
        `
      : null};
  svg {
    display: inline-block;
    margin-right: 8px;
    margin-top: -4px;
    vertical-align: middle;
  }
  @media (max-width: 1023px) {
    display: inline-block;
  }
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 20px;
  } ;
`;

const Desc = styled.div`
  font-size: 14px;
  line-height: 19px;
  color: #fff;
`;

const StreamSign = styled.div`
  text-align: center;
  @media (max-width: 1023px) {
    text-align: left;
    z-index: 100;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    background-color: ${colors.green};
    display: flex;
    justify-content: space-between;
  } ;
`;

const TextContent = styled.div``;

const IconBlockMobile = styled.div`
  display: none;
  background-color: #0bc958;
  border-radius: 50%;
  padding: 16.5px 19px 17.5px;
  svg {
    width: 13.95px;
    height: 10.15px;
  }
  @media (max-width: 1023px) {
    display: inline-block;
  } ;
`;

const Term = styled.a`
  font-size: 14px;
  line-height: 19px;
  text-decoration: underline;
  color: ${colors.grayMedium};
`;

const FixedWrapper = styled.div<{ isShow: boolean }>`
  position: fixed;
  top: ${(props) => (props.isShow ? 0 : -150)}px;
  left: 0;
  right: 0;
  background-color: #fff;
  border-bottom: 1px solid ${colors.grayLight2};
  padding: 12.5px 0;
  transition: 0.3s;
  z-index: 100;
  @media (max-width: 1023px) {
    display: none;
  } ;
`;

const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderStart = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  svg {
    margin-right: 15px;
    vertical-align: bottom;
  }
`;

const HeaderStreamSign = styled.div`
  display: flex;
  justify-content: space-between;
`;
export default PaidStream;
