import { useEffect } from 'react';

function useFixedVhProperty() {
  useEffect(() => {
    function updateVhProperty() {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          const vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty('--vh', `${vh}px`);
        });
      });
    }

    updateVhProperty();

    window.addEventListener('orientationchange', updateVhProperty);
    window.addEventListener('resize', updateVhProperty);

    return () => {
      window.removeEventListener('orientationchange', updateVhProperty);
      window.removeEventListener('resize', updateVhProperty);
    };
  }, []);
}

export default useFixedVhProperty;
