import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ContentContainer from '../../../../../../components/ContentContainer';
import { colors } from '../../../../../../constants/theme';

import MemberInfo from './components/MemberInfo';

type Props = {
  memberList: Array<{ id: string; avatarURL: string; name: string }>;
};
function Members({ memberList }: Props) {
  const { t } = useTranslation();

  return (
    <ContentContainer>
      <Content>
        <Title>{t('members')}</Title>
        <MemberList>
          {memberList.map((member, index) => (
            <Member key={index}>
              <MemberInfo
                name={member.name}
                image={member.avatarURL}
                login={'test'}
              />
            </Member>
          ))}
        </MemberList>
      </Content>
    </ContentContainer>
  );
}

const Content = styled.div`
  width: 100%;
  padding: 80px 0;
  border-top: 1px solid ${colors.grayLight2};
  @media (max-width: 1023px) {
    padding-bottom: 0;
  }
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 30px;
  @media (max-width: 1023px) {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 23px;
  }
`;

const MemberList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1023px) {
    display: block;
  }
`;

const Member = styled.li`
  display: inline-block;
  width: calc(100% / 4);
  margin-bottom: 20px;
  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 16px;
  }
`;

export default Members;
