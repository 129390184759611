import React from 'react';
import styled from 'styled-components';

import ContentContainer from '../../../../../../components/ContentContainer';

import AboutStream from './components/AboutStream';
import GetOnStream from './components/GetOnStream/GetOnStream';

type Props = {
  author: {
    id: string;
    avatarURL: string;
    name: string;
  };
  title: string;
  finishDate: number;
  startDate: number;
  price: number;
  description: string;
  hasChannelSubscription: boolean;
  moneyForCharity: boolean;
};

function Hero({
  author,
  title,
  finishDate,
  startDate,
  description,
  price,
  hasChannelSubscription,
  moneyForCharity,
}: Props) {
  return (
    <Container>
      <ContentContainer>
        <StreamInfoBlock>
          <AboutStream
            author={author}
            description={description}
            duration={finishDate - startDate}
            title={title}
            moneyForCharity={moneyForCharity}
          />
          <GetOnStream
            hasChannelSubscription={hasChannelSubscription}
            price={price}
            startDate={startDate}
          />
        </StreamInfoBlock>
      </ContentContainer>
    </Container>
  );
}

const Container = styled.div`
  background-color: #fff;
  width: 100%;
  padding: 80px 0;
  @media (max-width: 1023px) {
    padding: 47px 0 32px;
  }
`;

const StreamInfoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1023px) {
    flex-flow: column-reverse;
  } ;
`;

export default Hero;
