import { configureStore } from '@reduxjs/toolkit';

import streamInfoReducer from './streamInfo';
import userReducer from './user';
import authReducer from './auth';

export const store = configureStore({
  reducer: {
    streamInfo: streamInfoReducer,
    user: userReducer,
    auth: authReducer,
  },
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
