import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Elements, RecurlyProvider } from '@recurly/react-recurly';
import { useTranslation } from 'react-i18next';
import { lock } from 'tua-body-scroll-lock';

import { ModalProps } from '../../ModalProvider';
import { ReactComponent as CloseIcon } from '../../../assets/svg/close.svg';
import Loader from '../../Loader/Loader';

import PaymentForm from './components/PaymentForm';

type Props = ModalProps<{ channelId: string }>;

function PaymentModal({ closeModal, innerProps }: Props) {
  const { channelId } = innerProps;
  const scrollerBlockRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    lock(scrollerBlockRef.current);
  }, []);

  return (
    <Inner>
      <Close onClick={closeModal} />
      {isLoading ? <Loader /> : null}
      <Title>{t('paymentModal.title')}</Title>
      <ScrollerBlock ref={scrollerBlockRef}>
        <Description>{t('paymentModal.desc')}</Description>
        <RecurlyProvider publicKey="ewr1-TZAIwcPilISuHDCPr2X84I">
          <Elements>
            <PaymentForm
              setLoading={setLoading}
              channelId={channelId}
              closeModal={closeModal}
            />
          </Elements>
        </RecurlyProvider>
      </ScrollerBlock>
    </Inner>
  );
}

export default PaymentModal;

const Inner = styled.div`
  position: relative;
  background-color: #fff;
  z-index: 11;
  max-width: 405px;
  border-radius: 16px;
  padding: 40px;
  overflow: hidden;
  @media (max-width: 767px) {
    padding: 35px 24px;
  }
  @media (max-width: 374px) {
    height: calc((var(--vh, 1vh) * 100));
    width: 100vw;
    border-radius: unset;
  } ;
`;

const Close = styled(CloseIcon)`
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    opacity: 0.6;
  }
  @media (max-width: 767px) {
    top: 35px;
  } ;
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 8px;
  } ;
`;

const ScrollerBlock = styled.div`
  @media (max-width: 374px) {
    overflow: hidden auto;
    height: 100%;
    padding-bottom: 10px;
  } ;
`;

const Description = styled.span`
  display: inline-block;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 24px;
  } ;
`;
