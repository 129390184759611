import { AxiosResponse } from 'axios';

import { StreamInfoPageType, UserProfileType } from '../typings/model';

import { request } from './api';

export function createToken(
  uid: string | undefined
): Promise<AxiosResponse<{ api_token: string }>> {
  return request.post('/createUser', { firebaseId: uid });
}

export function getStreamInfoPage(
  channelId: string | undefined
): Promise<AxiosResponse<StreamInfoPageType>> {
  return request.get('/getChannelData', {
    params: { id: channelId },
  });
}

export function createSubscription(
  firebaseId: string | undefined,
  channelId: string,
  token: string
): Promise<AxiosResponse<Record<string, string>>> {
  return request.post('/purchaseChannelSubscription', {
    firebaseId,
    channelId,
    token,
  });
}

export function updateUserData(
  name?: string,
  nickname?: string,
  description?: string
): Promise<AxiosResponse<{ api_token: string }>> {
  return request.post('/updateUserData', {
    name,
    nickname,
    description,
  });
}

export function cancelSubscription(
  firebaseId: string | undefined,
  channelId: string
): Promise<AxiosResponse<Record<string, string>>> {
  return request.post('/cancelChannelSubscription', {
    firebaseId,
    channelId,
  });
}

export function joinChannel(
  channelId: string,
  uid: string | undefined
): Promise<AxiosResponse<{ channelToken: string }>> {
  return request.post('/joinChannel', {
    channelId,
    uid,
  });
}

export function getUserData(): Promise<AxiosResponse<UserProfileType>> {
  return request.get('/getUserData');
}
