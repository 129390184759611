import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

type Props = {
  text: string;
  icon?: ReactElement;
};

function Toast({ icon, text }: Props) {
  return (
    <ToastBlock>
      {icon ? <IconBlock>{icon}</IconBlock> : null}
      <ToastText>{text}</ToastText>
    </ToastBlock>
  );
}

export default Toast;

const ToastBlock = styled.div`
  text-align: center;
  background-color: #ffffff;
  border-radius: 16px;
  z-index: 10000000;
  width: 384px;
  box-sizing: border-box;
  padding: 20px 25px;
  @media (max-width: 767px) {
    width: 374px;
  }
  @media (max-width: 414px) {
    width: 335px;
  }
`;

const ToastText = styled.span`
  font-size: 13px;
  line-height: 18px;
  color: #000000;
`;

const IconBlock = styled.div`
  display: block;
  margin-bottom: 10px;
  svg {
    max-height: 24px;
  }
`;
