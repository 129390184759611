import React, { useEffect, useRef } from 'react';

interface Props {
  current?: number;
  radius: number;
  duration: number;
  end: number;
  loops?: number;
  reverse?: boolean;
  inverse?: boolean;
  showDuration?: boolean;
}

function SvgPieTimer({
  radius,
  duration,
  end,
  loops = 0,
  reverse,
  inverse,
}: Props) {
  const loaderRef = useRef<SVGPathElement>(null);

  const n = loops === 0 ? 0 : loops ? loops : 1;
  const totalDuration = duration * n;

  function draw(rate: number) {
    let angle = 360 * rate;

    angle %= 360;

    const rad = (angle * Math.PI) / 180;
    const x = Math.sin(rad) * (radius / 2);
    const y = Math.cos(rad) * -(radius / 2);
    let mid = angle > 180 ? 1 : 0;
    let sweepDirection = 1;

    if (inverse) {
      mid = Math.abs(mid - 1);
      sweepDirection = 0;
    }

    const shape =
      'M 0 0 v ' +
      -(radius / 2) +
      ' A ' +
      radius / 2 +
      ' ' +
      radius / 2 +
      ' 1 ' +
      mid +
      ' ' +
      sweepDirection +
      ' ' +
      x +
      ' ' +
      y +
      ' z';
    loaderRef.current?.setAttribute('d', shape);
  }

  function frame() {
    const now = new Date().getTime();
    const remaining = end - now;

    let rate = loops + 1 - remaining / duration;

    if (remaining < 60) {
      draw(loops - 0.0001);

      if ((remaining < totalDuration && loops) || !!totalDuration) return;
    }

    if (reverse) {
      rate = 360 - rate;
    }

    draw(rate);

    requestAnimationFrame(frame);
  }

  useEffect(() => {
    frame();
  }, []);

  return (
    <div className="svgPieTimer">
      <svg
        style={{ cursor: 'pointer' }}
        width={radius}
        height={radius}
        viewBox={'0 0 ' + radius + ' ' + radius}
      >
        <circle
          opacity="0.15"
          cx={radius / 2}
          cy={radius / 2}
          r={radius / 2}
          fill="white"
        />
        <path
          className="svg-loader"
          ref={loaderRef}
          transform={'translate(' + radius / 2 + ' ' + radius / 2 + ')'}
          fill="white"
        />
      </svg>
    </div>
  );
}
export default SvgPieTimer;
