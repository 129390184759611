import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import AgoraRTC, {
  IAgoraRTCClient,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
} from 'agora-rtc-sdk-ng';
import { useRouteMatch } from 'react-router-dom';
import { PubNubProvider } from 'pubnub-react';
import { toast, Toaster } from 'react-hot-toast';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { joinChannel } from '../../../../services/requests';
import { pubnub } from '../../../../config/pubnub';
import { StreamInfoPageType } from '../../../../typings/model';
import { ReactComponent as UserSvg } from '../../../../assets/svg/user.svg';
import { useAppSelector } from '../../../../store/hooks';
import Button from '../../../../components/Button';
import { ReactComponent as AudioAndCamIcon } from '../../../../assets/svg/audio-and-cam.svg';
import Loader from '../../../../components/Loader';

import Chat from './components/Chat/Chat';
import AuthorInformation from './components/AuthorInformation';
import Toast from './components/Toast';

type Props = {
  streamInfo: StreamInfoPageType;
};

export type StatusBroadcastingType = 'idle' | 'pending' | 'attached';

export interface Rtc {
  client: null | IAgoraRTCClient;
  localAudioTrack: null | IMicrophoneAudioTrack;
  localVideoTrack: null | ICameraVideoTrack;
}

function Stream({ streamInfo }: Props) {
  const router = useRouteMatch<{ channelId: string }>();
  const { t } = useTranslation();

  const rtcRef = useRef<Rtc>({
    client: null,
    localAudioTrack: null,
    localVideoTrack: null,
  });

  const otherBroadcasterBlockRef = useRef<HTMLDivElement>(null);
  const mainBroadcasterBlockRef = useRef<HTMLDivElement>(null);

  const [isStreamLoading, setStreamLoading] = useState(true);

  const [totalOccupancy, setTotalOccupancy] = useState(0);
  const [isHide, setHide] = useState(false);

  const [microphoneOff, setMicrophoneOff] = useState(true);
  const [cameraOff, setCameraOff] = useState(true);

  const [agoraUserID, setAgoraUserID] = useState<number | string | undefined>(
    ''
  );

  const [countStream, setCountStream] = useState<number>(0);
  const [isHost, setHost] = useState(false);

  const [
    statusBroadcastingRequest,
    setStatusBroadcastingRequest,
  ] = useState<StatusBroadcastingType>('idle');

  const [isAutoplayFailed, setAutoplayFailed] = useState(false);

  const { userData } = useAppSelector(
    (state) => ({
      userData: state.user.data,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (streamInfo) {
      if (streamInfo?.hasChannelSubscription && userData?.firebase_id) {
        joinChannel(router.params.channelId, userData.firebase_id).then(
          (joinResponse) => {
            // console.log(
            //   'joinResponse.data.channelToken',
            //   joinResponse.data.channelToken
            // );
            startBasicCall(joinResponse.data.channelToken);
          }
        );
      }
    }
  }, [streamInfo, userData]);

  AgoraRTC.onAudioAutoplayFailed = () => {
    setAutoplayFailed(true);
  };
  async function startBasicCall(joinResponse: string) {
    rtcRef.current.client = AgoraRTC.createClient({
      mode: 'live',
      codec: 'h264',
      role: 'audience',
    });
    rtcRef.current.client
      ?.join(
        '6eed186230a847678e96d058bf6bb5e1',
        router.params.channelId,
        joinResponse,
        userData?.firebase_id
      )
      .then(() => {
        rtcRef.current.client?.on(
          'user-published',
          async function (user, mediaType) {
            await rtcRef.current.client?.subscribe(user, mediaType);
            const remoteVideoTrack = user.videoTrack;
            const remoteAudioTrack = user.audioTrack;

            // console.log('user', user);

            if (remoteVideoTrack) {
              if (user.uid === streamInfo.authorId) {
                remoteVideoTrack.play(mainBroadcasterBlockRef.current ?? '');
                setHost(!!mainBroadcasterBlockRef.current?.children.length);
              } else {
                remoteVideoTrack.play(otherBroadcasterBlockRef.current ?? '');
              }
              setCountStream(
                (otherBroadcasterBlockRef.current?.children.length ?? 0) +
                  (mainBroadcasterBlockRef.current?.children.length ?? 0)
              );
            }
            if (remoteAudioTrack) {
              remoteAudioTrack.play();
            }
            setStreamLoading(false);
          }
        );
      });
    rtcRef.current.client?.on(
      'user-unpublished',
      async function (user, mediaType) {
        if (user.uid === streamInfo.authorId) {
          setHost(!!mainBroadcasterBlockRef.current?.children.length);
        }
        setCountStream(
          (otherBroadcasterBlockRef.current?.children.length ?? 0) +
            (mainBroadcasterBlockRef.current?.children.length ?? 0)
        );
      }
    );
    setAgoraUserID(userData?.firebase_id);
  }

  function joinToBroadcasting() {
    rtcRef.current.client?.setClientRole('host').then(async () => {
      try {
        rtcRef.current.localVideoTrack = await AgoraRTC.createCameraVideoTrack({
          optimizationMode: 'motion',
          encoderConfig: '480p',
        });
        rtcRef.current.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();

        rtcRef.current.client
          ?.publish([
            rtcRef.current.localAudioTrack,
            rtcRef.current.localVideoTrack,
          ])
          .then(() => {
            if (rtcRef.current.localVideoTrack) {
              rtcRef.current.localVideoTrack.play(
                otherBroadcasterBlockRef?.current ?? '',
                {
                  mirror: false,
                }
              );
              setCameraOff(false);
            }
            if (rtcRef.current.localAudioTrack) {
              setMicrophoneOff(false);
            }
          })
          .finally(() => {
            setCountStream(
              (otherBroadcasterBlockRef.current?.children.length ?? 0) +
                (mainBroadcasterBlockRef.current?.children.length ?? 0)
            );
          });
      } catch (error) {
        console.error('connect error', error);
        if (error.code === 'PERMISSION_DENIED') {
          toast(() => (
            <Toast icon={<AudioAndCamIcon />} text={t('stream.accessError')} />
          ));
          exitFromBroadcasting();
          setStatusBroadcastingRequest('idle');
        }
      }
    });
  }

  function exitFromBroadcasting() {
    rtcRef.current.client?.unpublish().then(() => {
      rtcRef.current.client?.setClientRole('audience');

      rtcRef.current.localAudioTrack?.close();
      rtcRef.current.localVideoTrack?.close();
      setCameraOff(true);
      setMicrophoneOff(true);
      setCountStream(
        (otherBroadcasterBlockRef.current?.children.length ?? 0) +
          (mainBroadcasterBlockRef.current?.children.length ?? 0)
      );
    });
  }

  function handleAudioPlay() {
    if (isAutoplayFailed) {
      rtcRef.current.client?.remoteUsers.forEach((remoteUser) => {
        remoteUser.audioTrack?.stop();
        remoteUser.audioTrack?.play();
      });
      setAutoplayFailed(false);
    }
  }
  return (
    <PubNubProvider client={pubnub(userData?.firebase_id)}>
      <Toaster
        toastOptions={{
          style: {
            padding: 0,
            maxWidth: 'unset',
            background: 'none',
            boxShadow: 'none',
            margin: '16px 0',
          },
          duration: 1500,
        }}
        position="top-center"
        reverseOrder={false}
      />
      <Container onClick={handleAudioPlay}>
        <ContentContainer>
          <StreamContainer>
            {isAutoplayFailed ? (
              <UnmuteButton variant={'primary'}>
                {t('stream.tapToUnmute')}
              </UnmuteButton>
            ) : null}
            <HideOverlay onClick={() => setHide(!isHide)} />
            {isStreamLoading ? <LoaderBlock withoutBg={true} /> : null}
            <AuthorBlock>
              <AuthorInformation
                name={streamInfo.author.name}
                channelTitle={streamInfo.title}
                avatarURL={streamInfo.author.avatarURL}
                finishDate={streamInfo.finishDate}
                startDate={streamInfo.startDate}
              />
            </AuthorBlock>
            <Users>
              <UserSvg />
              {totalOccupancy}
            </Users>
            <VideoBlock length={countStream} isHost={isHost}>
              <MainBroadcasterWrap>
                <MainBroadcaster ref={mainBroadcasterBlockRef} />
              </MainBroadcasterWrap>
              <OtherBroadcastersWrap>
                <OtherBroadcasters ref={otherBroadcasterBlockRef} />
              </OtherBroadcastersWrap>
            </VideoBlock>
            <ChatBlock isHide={isHide}>
              <Chat
                setTotalOccupancy={setTotalOccupancy}
                joinToBroadcasting={joinToBroadcasting}
                exitFromBroadcasting={exitFromBroadcasting}
                agoraUserID={agoraUserID}
                userData={userData}
                statusBroadcastingRequest={statusBroadcastingRequest}
                setStatusBroadcastingRequest={setStatusBroadcastingRequest}
                setMicrophoneOff={setMicrophoneOff}
                setCameraOff={setCameraOff}
                microphoneOff={microphoneOff}
                cameraOff={cameraOff}
                rtc={rtcRef.current}
              />
            </ChatBlock>
          </StreamContainer>
        </ContentContainer>
      </Container>
    </PubNubProvider>
  );
}

export default Stream;

const ContentContainer = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: auto;
`;

const UnmuteButton = styled(Button)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 9;
  font-size: 13px;
  background-color: #00b34996;
  padding: 10px 23px;
`;

const Container = styled.div`
  background-color: #1a1a1a;
  width: 100%;
  height: calc((var(--vh, 1vh) * 100));
  //@media (max-width: 767px) {
  //  height: calc((var(--vh, 1vh) * 100));
  //} ;
`;

const StreamContainer = styled.div`
  margin: 0 auto;
  max-width: 424.88px;
  max-height: 960px;
  //height: calc((var(--vh, 1vh) * 100));
  //position: relative;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  &:before {
    content: '';
    z-index: 1;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    height: 17.19%;
  }
  &:after {
    content: '';
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    pointer-events: none;
    height: 72.072%;
  }
  @media (max-width: 767px) {
    max-width: none;
    max-height: none;
    padding: 0;
    width: auto;
    border-radius: unset;
  } ;
`;

const HideOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;
const LoaderBlock = styled(Loader)`
  pointer-events: none;
`;

const AuthorBlock = styled.div`
  position: absolute;
  display: inline-block;
  top: 20px;
  left: 20px;
  z-index: 3;
`;

const Users = styled.div`
  position: absolute;
  border-radius: 49px;
  background-color: #00000015;
  font-weight: 600;
  font-size: 10px;
  color: #fff;
  line-height: 14px;
  right: 20px;
  top: 30px;
  padding: 6px 8px;
  z-index: 2;
  svg {
    margin-right: 4px;
    vertical-align: bottom;
  }
`;

const OtherBroadcasters = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  div {
    height: 100%;
  }
`;

const VideoBlock = styled.div<{ length: number; isHost: boolean }>`
  border-radius: 20px;
  //-webkit-mask-image: -webkit-radial-gradient(white, black);
  overflow: hidden;
  z-index: 0;
  height: 100%;
  display: grid;
  ${(props) =>
    props.isHost
      ? props.length === 1
        ? css`
            grid-template-rows: 100%;
            grid-template-columns: 100%;
            grid-template-areas: 'host';
          `
        : props.length === 2
        ? css`
            grid-template-areas:
              'host'
              'other';
          `
        : props.length === 3
        ? css`
            grid-template-areas:
              'host'
              'other';
            ${OtherBroadcasters} {
              div {
                flex: 1 1 50%;
              }
            }
          `
        : css`
            ${OtherBroadcasters} {
              div {
                flex: 1 1 50%;
                &:first-child {
                  position: absolute !important;
                  content: '';
                  top: 0;
                  right: 0;
                  width: 50% !important;
                  height: 50% !important;
                }
              }
            }
            grid-template: 'host .' 1fr 'other other' 1fr;
          `
      : props.length === 2
      ? css`
          grid-template-rows: minmax(auto, 50%);
          grid-template-columns: 100%;
          grid-template-areas: 'other';
        `
      : props.length === 3
      ? css`
          grid-template-rows: minmax(auto, 50%);
          grid-template-columns: 100%;
          grid-template-areas: 'other';
          ${OtherBroadcasters} {
            div {
              &:not(:first-child) {
                flex: 1 1 50%;
              }
            }
          }
        `
      : css`
          grid-template-areas: 'other';
          grid-template-rows: 100%;
          grid-template-columns: 100%;
        `};

  @media (max-width: 767px) {
    border-radius: unset;
  }
`;
const MainBroadcasterWrap = styled.div`
  grid-area: host;
`;

const MainBroadcaster = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`;

const OtherBroadcastersWrap = styled.div`
  grid-area: other;
`;

const ChatBlock = styled.div<{ isHide: boolean }>`
  position: absolute;
  bottom: 88px;
  width: 100%;
  z-index: 2;
  max-width: 424.88px;
  max-height: 284px;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s;

  ${(props) =>
    props.isHide
      ? css`
          opacity: 0;
          pointer-events: none;
        `
      : null}
`;
