import styled from 'styled-components';
import React, { useState } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';

import { isTouchScreen } from '../../../../../../../utils/common';
import { ReactComponent as BeautyFaceOnIcon } from '../../../../../../../assets/svg/beauty-face-on.svg';
import { ReactComponent as BeautyFaceIcon } from '../../../../../../../assets/svg/beauty-face.svg';
import { ReactComponent as CameraFlipIcon } from '../../../../../../../assets/svg/camera-flip.svg';
import { ReactComponent as CameraOffIcon } from '../../../../../../../assets/svg/camera-off.svg';
import { ReactComponent as CameraIcon } from '../../../../../../../assets/svg/camera.svg';
import { ReactComponent as MicrophoneOffIcon } from '../../../../../../../assets/svg/microphone-off.svg';
import { ReactComponent as MicrophoneIcon } from '../../../../../../../assets/svg/microphone.svg';
import { Rtc } from '../../../Stream';

interface Props {
  rtc: Rtc;
  setMicrophoneOff: (microphoneOff: boolean) => void;
  setCameraOff: (cameraOff: boolean) => void;
  microphoneOff: boolean;
  cameraOff: boolean;
}

function MoreAction({
  rtc,
  setMicrophoneOff,
  setCameraOff,
  microphoneOff,
  cameraOff,
}: Props) {
  const [isBeautyEffect, setBeautyEffect] = useState(false);

  function handleMicrophone() {
    if (microphoneOff) {
      return rtc.localAudioTrack?.setEnabled(true).then(() => {
        setMicrophoneOff(false);
      });
    }
    return rtc.localAudioTrack?.setEnabled(false).then(() => {
      setMicrophoneOff(true);
    });
  }

  function handleCamera() {
    if (cameraOff) {
      return rtc.localVideoTrack?.setEnabled(true).then(() => {
        setCameraOff(false);
      });
    }
    return rtc.localVideoTrack?.setEnabled(false).then(() => {
      setCameraOff(true);
    });
  }

  function handleBeautyEffect() {
    if (isBeautyEffect) {
      return rtc.localVideoTrack?.setBeautyEffect(false).then(() => {
        setBeautyEffect(false);
      });
    }
    return rtc.localVideoTrack?.setBeautyEffect(true).then(() => {
      setBeautyEffect(true);
    });
  }

  function handleSwitchCamera() {
    AgoraRTC.getCameras().then((mediaDevices) => {
      // console.log('mediaDevice', mediaDevices);
      const unUsedCams = mediaDevices.filter(
        (device) =>
          device.label !== rtc.localVideoTrack?.getMediaStreamTrack().label
      );
      if (unUsedCams.length) {
        rtc.localVideoTrack?.setDevice(unUsedCams[0].deviceId);
      }
    });
  }

  return (
    <Component>
      <CameraContentBlock>
        {!cameraOff ? (
          <CameraSettingsBlock>
            {!isTouchScreen() ? (
              <CameraSetting onClick={handleBeautyEffect}>
                {isBeautyEffect ? <BeautyFaceOnIcon /> : <BeautyFaceIcon />}
              </CameraSetting>
            ) : null}
            <CameraSetting onClick={handleSwitchCamera}>
              <CameraFlipIcon />
            </CameraSetting>
          </CameraSettingsBlock>
        ) : null}
        <CameraIconBlock onClick={handleCamera}>
          {cameraOff ? <CameraOffIcon /> : <CameraIcon />}
        </CameraIconBlock>
      </CameraContentBlock>
      <MicrophoneIconBlock onClick={handleMicrophone}>
        {microphoneOff ? <MicrophoneOffIcon /> : <MicrophoneIcon />}
      </MicrophoneIconBlock>
    </Component>
  );
}

export default MoreAction;

const Component = styled.div``;

const CameraContentBlock = styled.div`
  display: inline-block;
  padding-bottom: 27px;
  margin-bottom: 27px;
  border-bottom: solid 1px #ffffff20;
`;

const CameraSettingsBlock = styled.div`
  display: inline-block;
`;

const CameraSetting = styled.div`
  display: inline-block;
  cursor: pointer;
  margin-bottom: 30px;
`;

const CameraIconBlock = styled.div`
  display: inline-block;
  cursor: pointer;
`;

const MicrophoneIconBlock = styled.div`
  display: inline-block;
  cursor: pointer;
`;
