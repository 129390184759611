import React from 'react';

export const EN = {
  getOnStream: {
    joinIn: 'Join in',
    haveAccess: "Congratulations, you've got access!",
    startsIn: 'Starts in',
    streamingNow: 'Streaming now',
    termsOfUse: 'Terms of Use',
    signMeUp: 'SIGN ME UP',
    pay: 'pay',
  },

  aboutStream: {
    hoursStream: 'hours stream',
    minutesStream: 'minutes stream',
    moneyForCharity: 'Money for charity',
  },

  stream: {
    tapToUnmute: 'Tap to unmute',
    accessError: 'Please provide the access to continue',
  },

  rules: {
    rulesOfStream: 'Rules of stream',
    cart: {
      cartTitle1: 'Be yourself',
      cartDesc1: 'The authenticity of Streaming begins with the people.',
      cartTitle2: 'Join to Video Stream',
      cartDesc2: 'The authenticity of Streaming begins with the people.',
      cartTitle3: 'Be respectful',
      cartDesc3: 'This applies to every person, at all times',
      cartTitle4: 'Build empathy and understanding',
      cartDesc4: 'This applies to every person, at all times',
      cartTitle5: 'Rules of stream',
      cartDesc5: 'Rules of stream',
    },
  },

  chat: {
    broadcastingRequest:
      'You’ve asked for joining to the video stream. Wait for host solution.',
    shareLink: 'Share link copied to your clipboard',
    addComment: 'Add a comment…',
    dismissFromBroadcasting:
      '{{authorName}} dismissed your request of joining to live video stream.',
  },

  streamFinished: {
    title: 'Stream finished',
    desc: 'Stay tuned and join new streams whenever you want',
  },

  joinModal: {
    title: 'Join to live stream?',
    desc:
      'This will let the speakers know you have something you’d like to say',
    askStreaming: 'Ask for streaming',
  },

  loginModal: {
    title: 'Welcome to Streaming!',
    desc:
      'Please log in into your account to be able to enjoy the streams of our platform',
    appleButton: 'Sign in with Apple',
    facebookButton: 'Sign in with Facebook',
    googleButton: 'Sign in with Google',
  },

  paymentModal: {
    title: 'Payment',
    desc:
      'Fill your credit card info and complete the payment to get an access to the stream.',
    firstName: 'First name',
    lastName: 'Last name',
    cardNumber: 'Card number',
    expiresOn: 'Expires on',
    completePayment: 'complete payment',
    note:
      'To confirm your card, we will charge $1 to that card. Once confirmed, we will immediately refund the charge. Depending on your bank, it can take several hours/days for the refund to appear on your card statement.',
  },

  reportModal: {
    reportStream: 'Report Stream',
    cancel: 'Cancel',
  },

  pleaseContactSupport: 'Please contact support',

  members: 'Members',

  unknown: 'Unknown',

  homeScreen: {
    title:
      'Online video streaming platform for influencers and content creators',
    titleMobile: 'Online video streaming platform',
    desc:
      'Reach out to up to 1 mln audience, engaging followers from any social network. Monetize your events, support charity projects or run charity live streams.',
    copyright: 'Copyright 2021 All Rights Reserved',
  },
};
