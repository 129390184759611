import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { colors } from '../../../../constants/theme';

type Props = {
  name: string;
  avatarURL: string | undefined;
  login: string;
};

function StreamerInfo({ name, login, avatarURL }: Props) {
  const { t } = useTranslation();

  const splitName = name ? name.split(' ') : [];
  return (
    <PresenterData>
      {avatarURL ? (
        <ImageContainer>
          <Image src={avatarURL} />
        </ImageContainer>
      ) : splitName.length ? (
        <TextContainer>
          {splitName[0].substr(0, 1) + splitName[1].substr(0, 1)}
        </TextContainer>
      ) : (
        <TextContainer>{t('unknown').substr(0, 1)}</TextContainer>
      )}
      <StreamerData>
        <Name>{name ? name : t('unknown')}</Name>
        {/*<Website>@login</Website>*/}
      </StreamerData>
    </PresenterData>
  );
}

export default StreamerInfo;

const PresenterData = styled.div`
  display: block;
`;

const ImageContainer = styled.div`
  margin-bottom: 10px;
`;

const TextContainer = styled.div`
  border-radius: 50%;
  width: 48px;
  height: 48px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  background-color: ${colors.grayLight2};
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: 0 auto 13px;
  @media (max-width: 1023px) {
    margin-bottom: 8px;
  }
`;

const Image = styled.img`
  border-radius: 50%;
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin: auto;
`;

const StreamerData = styled.div`
  margin-left: 0;
  letter-spacing: -0.5px;
`;

const Name = styled.span`
  display: block;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
`;

const Website = styled.span`
  display: block;
  font-size: 14px;
  line-height: 19px;
  color: ${colors.grayMedium};
`;
