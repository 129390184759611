import React, { FormEvent, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  useRecurly,
  CardNumberElement,
  CardMonthElement,
  CardYearElement,
  CardCvvElement,
} from '@recurly/react-recurly';
import firebase from 'firebase';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import ReactGA from 'react-ga';

import { colors } from '../../../../constants/theme';
import Button from '../../../Button';
import {
  createSubscription,
  updateUserData,
} from '../../../../services/requests';
import TextInput from '../../../TextInput';
import { useAppSelector } from '../../../../store/hooks';

import '../paymentInput.css';

type Props = {
  channelId: string;
  setLoading: (isLoading: boolean) => void;
  closeModal: () => void;
};

function PaymentForm({ channelId, setLoading, closeModal }: Props) {
  const { t } = useTranslation();
  const recurly = useRecurly();

  const { userData } = useAppSelector(
    (state) => ({
      userData: state.user.data,
    }),
    shallowEqual
  );

  const [isValidCardNumber, setValidCardNumber] = useState(false);
  const [isValidCardMonth, setValidCardMonth] = useState(false);
  const [isValidCardYear, setValidCardYear] = useState(false);
  const [isValidCardCvv, setValidCardCvv] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [error, setError] = useState('');

  const formRef = useRef<HTMLFormElement>(null);
  const inputStyle = {
    fontColor: '#000000',
    fontSize: '18px',
    // fontFamily: 'Avenir Next',
    lineHeight: '25px',
    placeholder: { color: '#ABABAB' },
    border: `1px solid ${colors.grayLight2}`,
  };

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    setError('');
    setLoading(true);
    recurly.token(formRef.current as HTMLFormElement, async (err, token) => {
      if (err) {
        console.error(err);
        setError(err.message);
        setLoading(false);
      } else {
        if (!userData?.name) {
          await updateUserData(firstName + ' ' + lastName);
        }
        createSubscription(
          firebase.auth().currentUser?.uid,
          channelId,
          token.id
        )
          .then((response) => {
            ReactGA.event({
              category: 'User',
              action: 'Payment',
              label: userData?.email,
              dimension1: channelId,
            });
            document.location.reload();
          })
          .catch((error) => {
            console.error(error.response.data);
            setError(error.response.data.error);
          })
          .finally(() => setLoading(false));
      }
    });
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <NameInputs>
        <TextInputBlock>
          <Label>{t('paymentModal.firstName')}</Label>
          <NameInput
            data-recurly="first_name"
            id="first_name"
            value={firstName}
            onChange={setFirstName}
          />
        </TextInputBlock>
        <TextInputBlock>
          <Label>{t('paymentModal.lastName')}</Label>
          <NameInput
            data-recurly="last_name"
            id="last_name"
            value={lastName}
            onChange={setLastName}
          />
        </TextInputBlock>
      </NameInputs>
      <CardInputBlock>
        <Label>{t('paymentModal.cardNumber')}</Label>
        <CardNumberElement
          style={{
            ...inputStyle,
            placeholder: { content: 'XXXX XXXX XXXX XXXX' },
          }}
          inputType={'text'}
          onChange={(event) => setValidCardNumber(event.valid)}
          className={'className'}
        />
      </CardInputBlock>
      <OtherInputs>
        <DateInputBlock>
          <Label>{t('paymentModal.expiresOn')}</Label>
          <Inputs>
            <MonthInputBlock>
              <CardMonthElement
                style={{
                  ...inputStyle,
                  placeholder: { content: 'MM' },
                }}
                onChange={(event) => setValidCardMonth(event.valid)}
              />
            </MonthInputBlock>
            <YearInputBlock>
              <CardYearElement
                style={{
                  ...inputStyle,
                  placeholder: { content: 'YY' },
                }}
                onChange={(event) => setValidCardYear(event.valid)}
              />
            </YearInputBlock>
          </Inputs>
        </DateInputBlock>
        <CvvInputBlock>
          <Label>CVC</Label>
          <CardCvvElement
            style={{
              ...inputStyle,
              placeholder: { content: '•••' },
            }}
            onChange={(event) => setValidCardCvv(event.valid)}
          />
        </CvvInputBlock>
      </OtherInputs>
      <ButtonBlock>
        <PayButton
          disabled={
            !(
              isValidCardNumber &&
              isValidCardMonth &&
              isValidCardYear &&
              isValidCardCvv &&
              firstName &&
              lastName
            )
          }
          variant={'primary'}
          type={'submit'}
        >
          {t('paymentModal.completePayment')}
        </PayButton>
        {error ? <Error>{error}</Error> : null}
      </ButtonBlock>
      <Note>{t('paymentModal.note')}</Note>
    </Form>
  );
}

export default PaymentForm;

const Form = styled.form``;

const Label = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
`;

const NameInputs = styled.div`
  display: flex;
  margin: 0 -8.5px 30px;
`;

const TextInputBlock = styled.div`
  padding: 0 8.5px;
`;
const NameInput = styled(TextInput)`
  width: 100%;
  cursor: text;
  input {
    height: 54px;
  }
`;

const CardInputBlock = styled.div`
  margin-bottom: 30px;
`;

const DateInputBlock = styled.div``;

const Inputs = styled.div`
  display: flex;
`;

const MonthInputBlock = styled.div`
  margin-right: 8px;
  width: 100px;
`;

const YearInputBlock = styled.div`
  width: 100px;
`;

const CvvInputBlock = styled.div`
  width: 100px;
`;

const OtherInputs = styled.div`
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;
`;

const PayButton = styled(Button)`
  text-transform: uppercase;
  width: 100%;
  @media (max-width: 1023px) {
    font-size: 15px;
    line-height: 0;
    padding: 26px 40.5px;
  }
`;

const ButtonBlock = styled.div`
  position: relative;
`;

const Error = styled.div`
  position: absolute;
  color: red;
  left: 0;
  top: 100%;
  font-size: 12px;
`;

const Note = styled.div`
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: ${colors.grayMedium};
  margin-top: 20px;
`;
